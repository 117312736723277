import React, { Component } from 'react'
import {Box,Typography,Container, Divider} from '@mui/material'
import logo from "../src/logo.png"
import AdjustIcon from '@mui/icons-material/Adjust';
 const htmlText = "<b>hello</b>"
export class Bill extends Component {
    componentDidMount(){
        
    }
mm=()=>{
  /*
    var printContents = document.getElementById('dd').innerHTML;
        var originalContents = document.body.innerHTML;
   
        document.body.innerHTML = printContents;
   
        window.print();

        document.body.innerHTML = originalContents;
        */
}
   
  render() {
    return (
        <div id='dd'>
        <br/>
        <br/>
        <br/>
     
        <Box>
        <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box>

      <Box sx={{flex:1,marginLeft:{xs:0,sm:0,md:0,lg:0}}}>
      <img alt='logo' src={logo} style={{height:75,objectFit:'contain'}}/>
      </Box> 
<Divider/>
<br/>
<Box sx={{display:'flex',justifyContent:'space-between'}}>
 <Box>
      <Typography sx={{fontSize:13,fontWeight:'bold',color:'red'}}>INVOICE TO:</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>{this.props.data.firstname} {this.props.data.lastname},</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>{this.props.data.adress},</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>{this.props.data.city}- {this.props.data.postalcode},</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>{this.props.data.state},</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>{this.props.data.country},</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>{this.props.data.phone},</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>{this.props.data.email}</Typography>
      </Box>

      <Box>
      <Typography sx={{fontSize:13,fontWeight:'bold',}}>Booking date : {Date()}</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold',}}>Moving date : {this.props.data.date}</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold',}}>Invoice no : {this.props.data.receiptno}</Typography>
      <Typography sx={{fontSize:13,fontWeight:'bold',}}>Room no :</Typography>
      </Box>

</Box>


     
<br/>
<Divider/>
<Box sx={{display:'flex',justifyContent:'space-between'}}>
<Typography sx={{fontSize:16,fontWeight:'bold'}}>services</Typography>
<Typography sx={{fontSize:16,fontWeight:'bold'}}>Paid</Typography>
</Box>
<Divider/>
<br/>
<Box sx={{minHeight:250}}>
<Box sx={{display:'flex',justifyContent:'space-between'}}>
<Typography sx={{fontSize:16,fontWeight:'600',width:'70%'}}>{this.props.data.location}--{this.props.data.bed}</Typography>
<Typography sx={{fontSize:16,fontWeight:'600'}}>1000</Typography>
</Box>




      

</Box>
<Divider/>
<Box sx={{display:'flex',justifyContent:'space-between'}}>
<Typography sx={{fontSize:16,fontWeight:'600'}}>Total Amount</Typography>
<Typography sx={{fontSize:16,fontWeight:'600'}}>1000</Typography>
</Box>
<br/>
<br/>
<Box sx={{display:'flex',justifyContent:'space-between'}}>
 <Box>
      <Typography sx={{fontSize:13,fontWeight:'bold'}}>Customer sign</Typography>
      </Box>
      <Box>
      <Typography sx={{fontSize:13,fontWeight:'bold',}}>Reception sign</Typography>
      </Box>
</Box>

<br/>
<br/>
<br/>
<Box>


</Box>








      </Box>
      </Container>
       </Box>
        </Box>
      </div>
    )
  }
}

export default Bill