import { Container } from '@mui/system'
import React, { Component } from 'react'
import { Box, Typography,Grid ,Button,TextField,Divider, touchRippleClasses,Card} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate,useLocation } from 'react-router-dom';
import snp from "../src/Imgforp/snp.webp"
import sp from "../src/Imgforp/sp.jpg"
import poster from "../src/Imgforp/poster.png"
export class Blogs extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         data:[]
      }
    }


componentDidMount(){
    /*
  var ref = firebase.database().ref().child("Blogs");
        let data = [];
        ref.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
             
              var childData = childSnapshot.val();
              
              data.push(childData);

            this.setState({data:data})
             
              // ...
            });
          });
          
  */
}




mm=()=>{
    alert("jwkejkw")
}
next=(post)=>{
  console.log(post)
  const poo = post;
 this.props.navigate('/funtest',{state:{po:poo}})
}
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'pink',mt:6,display:{xs:'block',sm:'block',md:'block'}}}>
      <img
      className="d-block w-100"
      src={poster}
      alt="Second slide"
      style={{objectFit:'contain',width:'100vh'}}
    />
      </Box>
  



      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},width:'100%'}}>
      <Container maxWidth="xl">
      <Box>
      <Box>
      <br/>
      <Typography align='left' sx={{color:"black",fontSize:25,fontFamily:'sans-serif',fontWeight:'bold'}}>All Articals</Typography>
      </Box>
      </Box>
<br/>

      <Box sx={{}}>
      <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
      <Box sx={{minHeight:100}}>

      <Grid container spacing={2}>

      {
         datas.map((post ,index) =>
         <Grid item xs={12} sm={6} md={4} lg={4}>

         <Box sx={{backgroundColor:'white',borderRadius:3}}>
         <Box sx={{backgroundColor:'white',height:300,borderRadius:3}}>
         <Box sx={{borderRadius:3,height:270,backgroundColor:'white'}}>
         <Box
            component="img"
            sx={{
              height:270,
              display: 'block',
            objectFit:'cover',
              overflow: 'hidden',
              width:'100%',
              borderRadius:5
            }}
            src={sp}
            alt=''
          />

          <Box sx={{display:'flex',flexDirection:'row',mt:-2}}>
          <Button sx={{ml:3,borderRadius:4,backgroundColor:'#005f77',height:30}} variant="contained">pune</Button>
          <Button sx={{ml:1,backgroundColor:'white',color:'blue',borderRadius:4,height:30}} variant='outlined'>share</Button>
          </Box>


         </Box>
  </Box>
<Box sx={{height:80}}>
       <Typography align='justify' sx={{fontSize:20,color:'#00337b',fontFamily:'serif', display: '-webkit-box',
       overflow: 'hidden',
       WebkitBoxOrient: 'vertical',
       WebkitLineClamp: 2,}}>{post.heading}</Typography>
       </Box>
       <Typography sx={{color:'black',fontSize:12,fontWeight:'870', color:'#5c5c5c',}}>Dhiyaar living</Typography>
       <Typography sx={{color:'black',fontSize:12,fontWeight:'870', color:'#5c5c5c',}}>January 10,2023 | 05:41 PM</Typography>



   { post.paragraph.map((option,index) => 
             
      index==0?
      <Typography align='justify' sx={{
         mt:4,
         color:'#5c5c5c',
         fontSize:16,
         display: '-webkit-box',
         overflow: 'hidden',
         WebkitBoxOrient: 'vertical',
         WebkitLineClamp: 3,
     fontFamily:'serif'
     }}>
       {option.head}
        </Typography>
      :null
  )}

  <Button variant="contained" onClick={()=>this.next(post)} sx={{backgroundColor:'#c7167a ',height:30,marginTop:3,textTransform:'none'}}>Read more</Button>

      </Box>





        </Grid>
     
         )






     }




    </Grid>
      </Box>
      </Grid>
      
      </Grid>
      </Box>

      </Container>
      <br/>
      <br/>
      </Box>
      </div>
    )
  }
}




export function Blogc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Blogs navigate={navigate}></Blogs>)
}






export default Blogs




const datas = [

  {
    id:1,
    heading:"Giving your 100% on the exam day- hiking is best knowledge in coctail and registered coloing fav",
    paragraph:[
        {
        head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
       },
      
       {
        head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
       },]


},
{
    id:1,
    heading:"Romcoms, Drama Or Horror: Top Movies To Watch With Your Roommate ",
    paragraph:[
      {
      head:"If your idea of a fun-filled weekend consists of a laid-back movie night, then this list of movies will surely come in handy for you. Read on to know whether you wanna gush over the cheesy lines of the rom-com hero or if you want to challenge the daredevil in you with a horror flick."
     },
     {
      head:"If your idea of a fun-filled weekend consists of a laid-back movie night, then this list of movies will surely come in handy for you. Read on to know whether you wanna gush over the cheesy lines of the rom-com hero or if you want to challenge the daredevil in you with a horror flick."
     },
     {
      head:"If your idea of a fun-filled weekend consists of a laid-back movie night, then this list of movies will surely come in handy for you. Read on to know whether you wanna gush over the cheesy lines of the rom-com hero or if you want to challenge the daredevil in you with a horror flick."
     },
     ,]
},
{
id:2,
heading:"Festive Season in Indore: Best Activities To Enjoy With Your College Gang",
paragraph:[
    {
    head:"Living in a different and new city is an exciting experience, especially when it is done as a student. While the concept of students moving out of the comfort of their homes is not an unknown process, being away during the festive season can become slightly difficult. But the festive time away from loved ones can be easily overcome by spending it with your friends, especially your college family that you have found in the city you have selected to spend your younger years in. If your higher education has landed you in the city of Indore, then you are sure in for a fun-filled time."
   },
   
   {
    head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
   },]


},






   
   
   
   ]
   



