import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Typography,Link ,TextField, Button, ButtonGroup,IconButton, CardMedia, Card,Avatar,Rating} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {Divider} from '@mui/material';

import firebase, { database } from "../src/firebase"
import AppBar from '@mui/material/AppBar';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';

import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import JoinFullIcon from '@mui/icons-material/JoinFull';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import CampaignIcon from '@mui/icons-material/Campaign';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import ppp from '../src/sv.png'
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { useNavigate,useLocation } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';




function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}





export class Login extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      condition:false,
      rr:1,
     paragraph:[],
     para:"",
    paragraphhead:"",
    blog:[],

    posterhead:'',
    posterimg:'',
    backdrop:false,
    posterdata:[],
    register:[],
    enquery:[],
    announcement:[],
    v:'',
    noticehead:"",

    vs:'',
    noticeheads:"",

    vss:'',
    noticeheadss:"",
    newsandevents : [],


username:"",
password:"",
sss:"",

cont:[],
career:[],
mmmm:false,



open:false,



value:0,


////////
complain:"",
complainer:"",
compl:[],
data:[],
//////
addr:'',
area:'',
datee:'',
email:'',
uid:'',
landmark:'',
mobile:'',
pincode:'',
secondmobile:'',
namm:'',
ratename:"",

////
namep:'',
contactp:'',
problemp:'',
addrp:'',
    }
    
   
this.handleChange= this.handleChange.bind();
this.handleChangee=this.handleChangee.bind();
  }


  handleChange = (e) =>{
    this.setState({
        [e.target.name]:e.target.value,
    })
  }
  handleChangee = (e) =>{
    this.setState({
        [e.target.name]:e.target.files[0],
    })
  }





     
componentDidMount(){






}






blog=(props)=>{
  this.props.navigate("/funtest")
}

mm=()=>{
  const d = this.state.para;
  console.log(this.state.ss);
  this.setState({
 paragraph:this.state.paragraph.concat([{ss:this.state.ss}])
  });
  
}



 sm=()=>{
  alert("ok")
 }








  render(props) {
   
    return (
      <div>
      <Box sx={{}}>
<Toolbar/>
     <br/>
      <br/>
      
      <Container maxWidth="lg" >
      <Paper sx={{backgroundColor:'#fff',display:'flex',justifyContent:'center'}}>
      <Grid container >
      <Grid item xs={12} sm={12} md={6} lg={6}>
      <Box sx={{backgroundColor:'#00337b',minHeight:{xs:130,sm:130,md:400,lg:400}}}>
<br/>
      <Box sx={{display:'flex',justifyContent:'center'}} >
  <img src={ppp} style={{height:70,objectFit:'contain',backgroundColor:'white'}}/>
  </Box>
         
  <Box sx={{marginLeft:7,marginRight:7,display:{xs:'none',sm:'none',md:'block',lg:'block'}}}>
  <Typography align='center' sx={{fontSize:25,color:'white',fontWeight:600}}>India's top leading company </Typography>
  <Box sx={{backgroundColor:'#ffcc01'}}>
  <Typography align='center'sx={{m:1,fontSize:25,color:'blue',fontWeight:600}}>for hotels and </Typography>
  </Box>
  <Typography align='center' sx={{fontSize:25,color:'white',fontWeight:600}}>PG services</Typography>
  <br/>
  
  <Typography align='center' sx={{fontSize:25,color:'white',fontWeight:600}}>FOR FREE COUNSELLING</Typography>
  <br/>
  <Box sx={{display:'flex',justifyContent:'center'}}>
  <Button variant="contained" sx={{backgroundColor:'#ffcc01',color:'blue'}}>
  Enquire now
  </Button>
  </Box>
  </Box>

      </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box sx={{backgroundColor:'white',height:400,display:'flex',flexDirection:'column',marginLeft:{xs:1,sm:1,md:5,lg:5},marginRight:{xs:1,sm:1,md:5,lg:5}}}>
        <br/>
        <Typography align='center' sx={{m:2}}>Login Form</Typography>

        <TextField id="outlined-basic" label="Email id" variant="outlined" onChange={this.handleChange} name="username"  size='small' sx={{m:1,fontSize:10}}/>
        <TextField id="outlined-basic" label="Enter password" variant="outlined" onChange={this.handleChange}  name="password"  size='small' sx={{m:1}}/>
       <br/> <Link sx={{fontSize:13}} href='/register'>Not register? Register</Link>
       
        <Button variant="contained" sx={{width:200,backgroundColor:'#c7157a'}} onClick={()=>{
    if(this.state.username!==""&&this.state.password!==""){

   
          firebase.auth().signInWithEmailAndPassword( this.state.username,this.state.password)
          .then((userCredential) => {
            const user = firebase.auth().currentUser;

            if (user) {
            
              var uid = user.uid;
              localStorage.setItem("uid",uid)
            }


         this.props.navigate('/')
alert("Login succesful")
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            alert(errorMessage)
          });
 }else{
    alert("Fill all fields")
 }
      } }>Login</Button>
      <br/> <Link sx={{fontSize:13 ,textAlign:'center'}} href='/resetpassword'>Forgot password? Reset password</Link>

        
        </Box>
      </Grid>
    </Grid>






    </Paper>
      </Container>
      <br/>
      <br/>
      <br/>
      </Box>




      </div>
    )
  }
}



export function Loginc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Login navigate={navigate}></Login>)
}



export default Login


const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },]

    const date = [
        { label: 'Student', year: 1994 },
        { label: 'Admin', year: 1994 }
    ]
       

    




    const rows = [
      {
       id:";el;l",
       mm:"w;lpepl"
      },
      {
       id:";el;l",
       mm:"w;lpepl"
      },
      {
       id:";el;l",
       mm:"w;lpepl"
      }
     
     
     
     
     ];
     

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));



  




  