import React, { Component } from 'react'
import {Box,Container, Toolbar,Card, Typography, Button} from '@mui/material'
import firebase from "../src/firebase"
import { useNavigate,useLocation } from 'react-router-dom';
import Bill from './Bill';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



export class Bookedroom extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       d:'',
       data:[],
       uid:"",

       firstname:"",
       lastname:"",
       phone:"",
       email:"",
date:"",
postalcode:"",
city:"",
adress:"",
state:"Mharastra",
country:"India",
receiptno:"",
bed:"",
amount:'',
location:'',
ss:Date.now()

    }
  }
  componentDidMount(){
    var m = localStorage.getItem('uid')
if(m!==null){
var r = firebase.database().ref().child("Booking").child(m);
        let data = [];
        r.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
              var childData = childSnapshot.val();
              data.push(childData);
              this.setState({data:data})
console.log(data);
              // ...
            });
          });



    }else{
      this.props.navigate("/login")
    }

  }

print=(e)=>{

this.setState({receiptno:e.booking_id,date:e.moving_date,firstname:e.firstname,lastname:e.lastname,phone:e.phone,email:e.email,postalcode:e.postalcode,city:e.city,adress:e.Adress,state:e.state,country:e.country,bed:e.bed,location:e.location},()=>{
  var printContents = document.getElementById('mmm').innerHTML;
var originalContents = document.body.innerHTML;
document.body.innerHTML = printContents;
document.title=this.state.receiptno+this.state.firstname;
window.print();
document.body.innerHTML = originalContents;
})

 

}




  render() {
    return (
      <div>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%',minHeight:440}}>
      <Container maxWidth='xl'>
      <Box>
      <Toolbar/>
      <br/>
<Typography variant='h1' sx={{fontSize:18,fontWeight:'bold'}}>Booked Room / Hostel / PG</Typography>
<br/>




<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
  <TableHead>
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell align="center">Booking_id</TableCell>
      <TableCell align="center">Phone</TableCell>
      <TableCell align="center">Moving_date</TableCell>
      <TableCell align="center">Paid_amount</TableCell>
      <TableCell align="center">Location</TableCell>
      <TableCell align="center">Bed</TableCell>
      <TableCell align="center" sx={{display:{xs:'none',sm:'none',md:'block'}}}>Action</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {this.state.data?this.state.data.map((e) => (
      <TableRow
        key={e.firstname}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
        {e.firstname} {e.lastname}
        </TableCell>
        <TableCell align="center">{e.booking_id}</TableCell>
        <TableCell align="center">{e.phone}</TableCell>
        <TableCell align="center">{e.moving_date}</TableCell>
        <TableCell align="center">{e.amount}</TableCell>
        <TableCell align="center">{e.location}</TableCell>
        <TableCell align="center">{e.bed}</TableCell>
        <TableCell align="center" sx={{display:{xs:'none',sm:'none',md:'block'}}}><Button size='small' variant='contained' onClick={this.print.bind(this,e)}>Print</Button></TableCell>
      </TableRow>
    )):<Box><Typography>Nothing here (no booked PG)</Typography></Box>}
  </TableBody>
</Table>
</TableContainer>

















<Box sx={{display:'none'}}>
<div id='mmm'>
<Bill data={{receiptno:this.state.receiptno,firstname:this.state.firstname,lastname:this.state.lastname,phone:this.state.phone,email:this.state.email,city:this.state.city,postalcode:this.state.postalcode,adress:this.state.adress,date:this.state.date,state:this.state.state,country:this.state.country,location:this.state.location,bed:this.state.bed}}/>
</div>
</Box>
      </Box>
      </Container>
</Box>
      </div>
    )
  }
}



export function Bmc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Bookedroom navigate={navigate}></Bookedroom>)
}






export default Bookedroom