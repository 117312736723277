import React,{ useState, useEffect }  from 'react'
import {useLocation} from 'react-router-dom';
import { Container } from '@mui/system'
import sp from "../src/Imgforp/sp.jpg"
import { Box, Typography,Grid ,Button,TextField,Divider, Toolbar} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle';
import poster from "../src/Imgforp/poster.png"

function Funtest() {
  const location = useLocation();
  const [Post, setPost] = useState([]);
  const [Data, setData] = useState([]);

  

//setPost(location.state.id)
useEffect(()=>{
  setPost(location.state.po)
  console.log(location.state.po)
/*
  var ref = firebase.database().ref().child("Blogs");
  let data = [];
  ref.once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
       
        var childData = childSnapshot.val();
        
        data.push(childData);

     setData(data);
       
        // ...
      });
    });
*/
},[]);

console.log(Post)
  return (
    <div>
    
<Toolbar/>

    <Box sx={{backgroundColor:'#f1f4f6'}}>
    <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},width:'100%'}} >
    <Container maxWidth="xl">
    <Box sx={{backgroundColor:"#00337b",height:130,}}>
    <Box>
    <br/>
    <Typography align='left' sx={{ml:2,color:"white",fontSize:25,fontFamily:'sans-serif'}}>Insights by Dhiyaar Living </Typography>
    <Typography align='left' sx={{ml:2,color:"white",fontSize:17,fontFamily:'sans-serif'}}>Official Dhiyaar Living blog about living, hostal, PG services</Typography>
    </Box>
    </Box>


    <Box sx={{}}>
    <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }} >
    <Grid item xs={12} sm={12} md={8} lg={8} >
    <Box sx={{minHeight:100}}>

    <Grid container >
    <Grid item xs={12} sm={12} md={12} lg={12}>
    <br/>
    <Box sx={{display:'flex',flexDirection:'row',mt:-2}}>
          <Button sx={{ml:3,borderRadius:4,backgroundColor:'#005f77',height:30}} variant="contained">Receive</Button>
          <Button sx={{ml:1,backgroundColor:'white',color:'blue',borderRadius:4,height:30}} variant='outlined'>share</Button>
          </Box>
<br/>
    <Typography  align='left' sx={{fontSize:{xs:21,sm:21,md:26,lg:26},color:"#00337b",fontFamily:'serif'}}>{Post.heading}</Typography>
    <Typography sx={{color:'black',fontSize:12,fontWeight:'870', color:'#5c5c5c',}}>Dhiyaar living</Typography>
    <Typography sx={{color:'black',fontSize:12,fontWeight:'870', color:'#5c5c5c',}}>January 10,2023 | 05:41 PM</Typography>
<br/>


         <Box sx={{backgroundColor:'white',minHeight:300,borderRadius:3}}>
         <Box sx={{borderRadius:3,minHeight:300,backgroundColor:'white'}}>
         <Box
            component="img"
            sx={{
              height:{xs:300,sm:300,md:500},
              display: 'block',
            objectFit:'cover',
              overflow: 'hidden',
              width:'100%',
              borderRadius:5
            }}
            src={sp}
            alt=''
          />
         </Box>
  </Box>
       <br/>
   
   { 
    Post.paragraph? 
      Post.paragraph.map((option,index) => 
      <Box>
      <br/>
      <Typography  align='justify' sx={{fontSize:{xs:17,sm:17,md:19,lg:19},color:"#00337b",fontFamily:'serif'}}>{option.head}</Typography>
      </Box>

      ):null

   }





    </Grid>
    
  </Grid>
    </Box>
    </Grid>
    <Grid item xs={12} sm={12} md={4} lg={4}>
    <br/>
    <Box sx={{backgroundColor:'#f1f4f6',minHeight:50,marginLeft:2 ,display:'flex',justifyContent:'center'}}>
    <Box sx={{m:2}}>
    <TextField id="outlined-search" label="Search here....." size='small'  type="search" />
    <br/>
    </Box>
    </Box>
<br/>
    <Box sx={{backgroundColor:'#f1f4f6',height:750,marginLeft:2 }}>
    <Box sx={{display:'flex',flexDirection:'row',}}>
    <Typography sx={{color:'red',fontFamily:'sans-serif',mt:2,ml:2,fontWeight:'bold'}}>Blogs Heading</Typography>
       </Box>


    <Box sx={{maxHeight:700,overflow:'scroll',mb:2,overflowX:'hidden',overflowY:'hidden' ,'&:hover': {overflowY:'scroll'}}}>


    {
      data.map((post ,index) =>
      <Box>
    
      <Box sx={{backgroundColor:'#f1f4f6',borderRadius:3}}>
      <Box sx={{backgroundColor:'#f1f4f6',height:200,borderRadius:3}}>
      <Box sx={{borderRadius:3,height:170,backgroundColor:'#f1f4f6'}}>
      <Box
         component="img"
         sx={{
           height:170,
           display: 'block',
         objectFit:'cover',
           overflow: 'hidden',
           width:'100%',
           borderRadius:5
         }}
         src={sp}
         alt=''
       />

       <Box sx={{display:'flex',flexDirection:'row',mt:-2}}>
       <Button sx={{ml:3,borderRadius:4,backgroundColor:'#005f77',height:30}} variant="contained">pune</Button>
       <Button sx={{ml:1,backgroundColor:'white',color:'blue',borderRadius:4,height:30}} variant='outlined'>share</Button>
       </Box>


      </Box>
</Box>

    <Typography align='justify' sx={{fontSize:14,height:45,color:'#5c5c5c',fontFamily:'serif', display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,}}>{post.heading}</Typography>
    <Typography sx={{color:'black',fontSize:10,fontWeight:'770', color:'#5c5c5c',}}>Dhiyaar living</Typography>
    <Typography sx={{color:'black',fontSize:10,fontWeight:'770', color:'#5c5c5c',}}>January 10,2023 | 05:41 PM</Typography>



{ post.paragraph.map((option,index) => 
          
   index==0?
   <Typography align='justify' sx={{
      mt:4,
      color:'#5c5c5c',
      fontSize:12,
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
  fontFamily:'serif'
  }}>
    {option.head}
     </Typography>
   :null
)}

<Button variant="contained" onClick={()=>setPost(post)} sx={{backgroundColor:'#c7167a ',height:30,marginTop:3,textTransform:'none'}}>Read more</Button>

   </Box>
<br/>
      <Divider sx={{color:'white',marginLeft:3,marginRight:3}}/>
      </Box>
      )
  }



</Box>

    </Box>


<br/>


<Box sx={{backgroundColor:'#ededef',height:100,marginLeft:2 }}>
<Typography  align='center'>Comments</Typography>



</Box>











    </Grid>
    </Grid>
    </Box>

    </Container>
    <br/>
    <br/>
    </Box>
    </Box>
    </div>
  )
}

export default Funtest


const data = [

  {
      id:1,
      heading:"Giving your 100% on the exam day- hiking is best knowledge in coctail and registered coloing fav",
      paragraph:[
          {
          head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
         },
        
         {
          head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
         },]


  },
  {
      id:1,
      heading:"Romcoms, Drama Or Horror: Top Movies To Watch With Your Roommate ",
      paragraph:[
        {
        head:"If your idea of a fun-filled weekend consists of a laid-back movie night, then this list of movies will surely come in handy for you. Read on to know whether you wanna gush over the cheesy lines of the rom-com hero or if you want to challenge the daredevil in you with a horror flick."
       },
       {
        head:"If your idea of a fun-filled weekend consists of a laid-back movie night, then this list of movies will surely come in handy for you. Read on to know whether you wanna gush over the cheesy lines of the rom-com hero or if you want to challenge the daredevil in you with a horror flick."
       },
       {
        head:"If your idea of a fun-filled weekend consists of a laid-back movie night, then this list of movies will surely come in handy for you. Read on to know whether you wanna gush over the cheesy lines of the rom-com hero or if you want to challenge the daredevil in you with a horror flick."
       },
       ,]
},
{
  id:2,
  heading:"Festive Season in Indore: Best Activities To Enjoy With Your College Gang",
  paragraph:[
      {
      head:"Living in a different and new city is an exciting experience, especially when it is done as a student. While the concept of students moving out of the comfort of their homes is not an unknown process, being away during the festive season can become slightly difficult. But the festive time away from loved ones can be easily overcome by spending it with your friends, especially your college family that you have found in the city you have selected to spend your younger years in. If your higher education has landed you in the city of Indore, then you are sure in for a fun-filled time."
     },
     
     {
      head:"JEE is not just about memorising and retrieving, it needs the right strategy and perfect execution. So instead of learning anything new now, you may just review main points, write mock tests, be in your best state and give it all that you have on the D day."
     },]


},





  
  
  
  ]