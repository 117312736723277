import React, { Component } from 'react'
import { Container,Typography,Card,Grid,Divider,TextField,Button,Box,Link,} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DeleteIcon from '@mui/icons-material/Delete';
import AdjustIcon from '@mui/icons-material/Adjust';
import firebase from "../src/firebase"
import { useNavigate,useLocation } from 'react-router-dom';
import Bill from './Bill';
export class Cheakout extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        firstname:"",
        lastname:"",
        phone:"",
        email:"",
date:"",
postalcode:"",
city:"",
adress:"",
state:"Mharastra",
country:"India",
receiptno:Date.now(),
bed:"",
amount:'',
location:'',
uid:''

      }
      this.handlechange=this.handlechange.bind()
    }

componentDidMount(){
 

  const user = firebase.auth().currentUser;

  if (user) {
   var uidd = user.uid;
this.setState({uid:uidd})
  } else {
    
    this.props.navigate('/login')
  }



/*
  var printContents = document.getElementById('mmm').innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  document.title=this.state.receiptno+this.state.firstname;
  window.print();
  document.body.innerHTML = originalContents;
*/



  const bed = localStorage.getItem("bed");
  const amount = localStorage.getItem("amount");
  const location = localStorage.getItem("location");
  this.setState({bed:bed,amount:amount,location:location});

}

mc=()=>{
  this.props.navigate('/bookedroom')
}

pay=()=>{

      const firstname = this.state.firstname
const lastname = this.state.lastname
const city = this.state.city
const postalcode = this.state.postalcode
const state = this.state.state
const country = this.state.country
const Adress = this.state.adress
const phone = this.state.phone
const date = this.state.date
var uid = this.state.uid;
const location= this.state.location;
const bed = this.state.bed;
const receiptno=this.state.receiptno
  if(this.state.firstname!==""&&this.state.lastname!==""&&this.state.adress!=="" && this.state.city!==""&&this.state.phone!==""&&this.state.postalcode!==""&&this.state.date!=="")
  {
    var rzp1 = new window.Razorpay({
    key: 'rzp_test_DIwtLXaqbgtWMn',
    amount: '100000', 
    name: 'Dhiyaar Living',
    description: 'Services like PG Hostel',
    handler: function(response) {
       // alert(response.razorpay_payment_id);
        const key= firebase.database().ref("contactform").push().key
        firebase.database().ref("Booking").child(uid).child(key).set({
      firstname:firstname,
      lastname:lastname,
      location:location,
      bed:bed,
      city:city,
      postalcode:postalcode,
      state:state,
      uid:uid,
      country:country,
      moving_date:date,
      Adress:Adress,
      phone:phone,
      key:key,
      amount:'1000',
      mode:'online',
      payment_id:response.razorpay_payment_id,
      booking_id:receiptno,
      
        }).then((r)=>{
          firebase.database().ref("Bookingforused").child(key).set({
            firstname:firstname,
            lastname:lastname,
            amount:'1000',
            mode:'online',
            location:location,
             bed:bed,
            city:city,
            postalcode:postalcode,
            state:state,
            uid:uid,
            country:country,
            moving_date:date,
            Adress:Adress,
            phone:phone,
            key:key,
            payment_id:response.razorpay_payment_id,
            booking_id:receiptno
          }).then(()=>{
            alert('Booking successful, view in bookedroom')
          })

      
/*
          var printContents = document.getElementById('mmm').innerHTML;
          var originalContents = document.body.innerHTML;
          document.body.innerHTML = printContents;
          document.title=receiptno+firstname;
          window.print();
          document.body.innerHTML = originalContents;
*/
        })
    }
});
rzp1.open();
  }else{
    alert("Fill all field below!")
 }
  


   
  










/*
  var printContents = document.getElementById('mmm').innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
  */
}

handlechange=(e)=>{
this.setState({[e.target.name]:e.target.value})
}


  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'#f1f4f6',minHeight:600}}>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box>
  <br/>
  <br/>
  <br/>
      
  <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 5 }}>
  <Grid item xs={12} sm={12} md={8}>
   <Box sx={{backgroundColor:'white',minHeight:300}}>
   <br/>
   <Box sx={{ml:2,mt:1}}>
   <Typography sx={{fontSize:15,fontWeight:'bold',fontFamily:'serif',color:'#c7167a'}}>Please Enter Your Personal Information</Typography>
   </Box>
   <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 9 }}>
  <Grid item xs={12} sm={12} md={6}>
    <Box sx={{m:2}}>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>First name *</Typography>
    <TextField
    error={this.state.firstname==""}
    name="firstname"
    onChange={this.handlechange}
    fullWidth
    id="filled-password-input"
placeholder='Name'
    type="text"
    autoComplete="current-password"
    variant='standard'
    inputProps={{
        fontSize:14
    }}
  />
    
    </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6}>
  <Box sx={{m:2}}>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>Last name *</Typography>
    <TextField
    fullWidth
    error={this.state.lastname==""}
    name="lastname"
    onChange={this.handlechange}
    id="filled-password-input"
placeholder='Last name'
    type="text"
    autoComplete="current-password"
    variant='standard'
    inputProps={{
        fontSize:14
    }}
  />
    
    </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6}>
  <Box sx={{m:2}}>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>Phone *</Typography>
    <TextField
    fullWidth
    error={this.state.phone==""}
    name="phone"
    onChange={this.handlechange}
    id="filled-password-input"
placeholder='Phone'
    type='tel'
    autoComplete="current-password"
    variant='standard'
    inputProps={{
        fontSize:14
    }}
  />
    
    </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6}>
  <Box sx={{m:2}}>
  <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>Email *</Typography>
  <TextField
  fullWidth
  error={this.state.email==""}
  name="email"
  onChange={this.handlechange}
  id="filled-password-input"
placeholder='Email'
  type='email'
  autoComplete="current-password"
  variant='standard'
  inputProps={{
      fontSize:14
  }}
/>
  
  </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6}>
  <Box sx={{m:2}}>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>Post code / ZIP *</Typography>
    <TextField
    fullWidth
    error={this.state.postalcode==""}
    name="postalcode"
    onChange={this.handlechange}
    id="filled-password-input"
placeholder='Post code / ZIP'
    type='number'
    autoComplete="current-password"
    variant='standard'
    inputProps={{
        fontSize:14
    }}
  />
    
    </Box>
</Grid>
<Grid item xs={12} sm={12} md={6}>
<Box sx={{m:2}}>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>City *</Typography>
    <TextField
    fullWidth
    error={this.state.city==""}
    name="city"
    onChange={this.handlechange}
    id="filled-password-input"
placeholder='City'
    type="text"
    autoComplete="current-password"
    variant='standard'
    inputProps={{
        fontSize:14
    }}
  />
    
    </Box>
</Grid>
<Grid item xs={12} sm={12} md={6}>
<Box sx={{m:2}}>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>Adress *</Typography>
    <TextField
    fullWidth
    error={this.state.adress==""}
    name="adress"
    onChange={this.handlechange}
    id="filled-password-input"
placeholder='Adress'
    type="text"
    autoComplete="current-password"
    variant='standard'
    inputProps={{
        fontSize:14
    }}
  />
    
    </Box>
</Grid>
  <Grid item xs={12} sm={12} md={6}>
  <Box sx={{m:2}}>
  <Typography sx={{fontSize:14,fontWeight:'bold',ml:0.1}}>Moving date *</Typography>
  <TextField
  fullWidth
  error={this.state.date==""}
  name="date"
  onChange={this.handlechange}
  id="filled-password-input"
placeholder='Moving date'
  type='date'
  autoComplete="current-password"
  variant='standard'
  inputProps={{
      fontSize:14
  }}
/>
  
  </Box>
</Grid>
<Grid item xs={12} sm={12} md={6}>
<Box sx={{m:2}}>
<TextField
fullWidth
          value='India'
          id="outlined-required"
          label="Required"
          
        />
        </Box>
</Grid>
<Grid item xs={12} sm={12} md={6}>
<Box sx={{m:2}}>
<TextField
fullWidth
          value='Maharastra'
          id="outlined-required"
          label="Required"
        
        />
        </Box>
</Grid>
</Grid>
   </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={4}>
   <Box sx={{backgroundColor:'white',minHeight:300}}>
   <Box sx={{backgroundColor:'#5cd1d5',display:'flex',justifyContent:'space-between'}}>
   <Typography sx={{m:2,fontSize:16,fontWeight:'bold'}}>Property</Typography>
   <Typography sx={{m:2,fontSize:16,fontWeight:'bold'}} >Subtotal</Typography>
   </Box>
<Divider/>
<Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between'}}>
<Typography sx={{m:2,fontSize:15,fontWeight:'700'}}>PG in {this.state.location} - {this.state.bed} × 1</Typography>
</Box>
<Divider/>
   <Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between'}}>
   <Typography sx={{m:2,fontSize:16,fontWeight:'bold'}}>Booking fee</Typography>
   <Typography sx={{m:2,fontSize:16,fontWeight:'bold'}} >1000</Typography>
   </Box>
<Divider/>
<Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'space-between'}}>
<Typography sx={{m:2,fontSize:16,fontWeight:'bold'}}>Total</Typography>
<Typography sx={{m:2,fontSize:16,fontWeight:'bold'}} >1000</Typography>
</Box>

<Box sx={{backgroundColor:'#e1e9e9',display:'flex',justifyContent:'left',}}>
<Typography sx={{m:2,fontSize:16,fontWeight:'bold'}}>Please Confirm Your Booking</Typography>
</Box>

<Box sx={{backgroundColor:'#fff',display:'flex',justifyContent:'left', '&:hover': {
    backgroundColor: '#5cd1d5',
   
  },}}>
<Typography sx={{m:2,fontSize:16,fontWeight:'bold'}}>Online payment</Typography>
</Box>
<Box sx={{backgroundColor:'#e9efef'}}>
<br/>
<br/>
<Box onClick={this.pay} sx={{}}>
<Box sx={{backgroundColor:'#c7157a',display:'flex',justifyContent:'center',mt:1, }}>
<Typography sx={{m:2,fontSize:16,fontWeight:'bold',color:'white'}}>Confirm Booking</Typography>
</Box>

</Box>
</Box>


   </Box>
  </Grid>
</Grid>  










      </Box>
     </Container>
      </Box>
      <br/>
      <br/>
      </Box>
      </div>
    )
  }
}


export function Cheakoutc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Cheakout navigate={navigate}></Cheakout>)
}


export default Cheakout