import { Box, Toolbar, Typography,Grid, Card, TextField, Button, Divider } from '@mui/material'
import { Container } from '@mui/system'
import React, { Component } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Horrizontalscroll from './Horrizontalscroll';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import Mobilehorrizontal from './Mobilehorrizontal';
import BookIcon from '@mui/icons-material/Book';
import firebase from "../src/firebase"
import { useNavigate,useLocation } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AddHomeIcon from '@mui/icons-material/AddHome';
import Groups2Icon from '@mui/icons-material/Groups2';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import boy from "../src/img/boy.png"
import girl from "../src/img/girl.png"

import GirlIcon from '@mui/icons-material/Girl';
import BoyIcon from '@mui/icons-material/Boy';

import imgone from "../src/img/imgtwo.webp"
import tt from "../src/img/tt.webp"
import ttt from "../src/img/ttt.webp"
import Scroller from './Scroller';
import backg from "../src/img/backg.jpg"
import Geocode from "react-geocode";
import map2 from "../src/img/map2.png"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
//import firebase from "../src/firebase"
export class Pageone extends Component {
constructor(props) {
  super(props)
  this.loctwo = this.loctwo.bind(this)
  this.state = {
    city:"",
    data:[],
  }

}

  componentDidMount(){
   this.mm();
   var v = localStorage.getItem('uid')
   console.log(v)

   var ref = firebase.database().ref().child("other_pg");
   let data = [];
   ref.once('value', (snapshot) => {
       snapshot.forEach((childSnapshot) => {
        
         var childData = childSnapshot.val();
         
         data.push(childData);

       this.setState({data:data})
        
         // ...
       });
     });
     




  }

mm=()=>{
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {console.log(position.coords)})
    fetch('https://api.opencagedata.com/geocode/v1/json?q=18.530304,73.8459648&key=d22bb4f8b3c44c75b4eb003effac9d52&language=en&pretty=1').then((response) => {
      response.json().then((m)=>{
      console.log(m.results[0].components.state_district)
      this.setState({city:m.results[0].components.state_district})
      })})
  } else {
    // I believe it may also mean geolocation isn't supported
    console.log("denied")
    alert('Geolocation denied') 
  }



   navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      console.log(position.coords)
      // Show a map centered at latitude / longitude.
       fetch('https://api.opencagedata.com/geocode/v1/json?q='+latitude+','+longitude+'&key=d22bb4f8b3c44c75b4eb003effac9d52&language=en&pretty=1').then((response) => {
     response.json().then((m)=>{
      console.log(m)
     //console.log(m.results[0].components.state_district)
     this.setState({city:m.results[0].components.state_district})
     })
 
    });

    });

   
}
  



locone=()=>{
  
       this.props.navigate('/formc')
  localStorage.setItem('location',"Gulmohar Regency Building, No: 7 ")
   
}

loctwo=async()=>{
 
      this.props.navigate('/formc');
  localStorage.setItem('location',"Sanjay park Main Rd, Survey Number 230 ")
   
}

locthree=()=>{
 
      this.props.navigate('/formc')
  localStorage.setItem('location',"Rajiv Nagar South, Mhada Colony")
   
  
}


///////
locfour=()=>{
 
  this.props.navigate('/formc')
localStorage.setItem('location',"Mhada Colony, Viman Nagar, Pune, Maharashtra 411014")
}


locfive=()=>{
  this.props.navigate('/formc')
localStorage.setItem('location',"HW86+JWV, Konark Nagar, Clover Park, Viman Nagar, Pune, Maharashtra 411014")
}


locsix=()=>{
  this.props.navigate('/formc')
localStorage.setItem('location',"Dhiyaar-Living Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014")


}
















view=()=>{

  if( (navigator.platform.indexOf("iPhone") != -1) 
  || (navigator.platform.indexOf("iPod") != -1)
  || (navigator.platform.indexOf("iPad") != -1))
   window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.569833941480397,73.91587583535856");
else
   window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.569833941480397,73.91587583535856");
}

renderMenu(){
    return(
      <Box sx={{ height:100,backgroundColor:'blue'}}>
    
      </Box>
     )
  }
viewthree=()=>{

  

  if( (navigator.platform.indexOf("iPhone") != -1)
  || (navigator.platform.indexOf("iPod") != -1)
  || (navigator.platform.indexOf("iPad") != -1))
   window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.565242296593283,73.91403528901185");
else
   window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.565242296593283,73.91403528901185");
}


viewtwo=()=>{

  if( (navigator.platform.indexOf("iPhone") != -1) 
  || (navigator.platform.indexOf("iPod") != -1)
  || (navigator.platform.indexOf("iPad") != -1))
   window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.56835669969663,73.91238846970238");
else
   window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.56835669969663,73.91238846970238");
}


///18.573768392536493, 73.91063504803198


viewfour=()=>{

  if( (navigator.platform.indexOf("iPhone") != -1) 
  || (navigator.platform.indexOf("iPod") != -1)
  || (navigator.platform.indexOf("iPad") != -1))
   window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.573768392536493,73.91063504803198");
else
   window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.573768392536493,73.91063504803198");
}

//18.566621645590683, 73.91230310584619

viewfive=()=>{

  if( (navigator.platform.indexOf("iPhone") != -1) 
  || (navigator.platform.indexOf("iPod") != -1)
  || (navigator.platform.indexOf("iPad") != -1))
   window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.566621645590683,73.91230310584619");
else
   window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.566621645590683,73.91230310584619");
}



viewsix=()=>{

  if( (navigator.platform.indexOf("iPhone") != -1) 
  || (navigator.platform.indexOf("iPod") != -1)
  || (navigator.platform.indexOf("iPad") != -1))
   window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.56835669969663,73.91238846970238");
else
   window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=18.56835669969663,73.91238846970238");
}













  render() {
    return (
      <div>
     
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
      <Container maxWidth='xl'>
    <Box sx={{minHeight:400}}>
    <Grid container spacing={1}>
    <Grid item xs={12} sm={12} md={6} lg={6}>
     <Box sx={{backgroundColor:'#ffffff',height:'100%'}}>
     <br/>
     <br/>
<Typography variant='h1' sx={{fontSize:{xs:33,sm:45,md:45,lg:45},textAlign:{xs:'center',sm:'center',md:'left',lg:'left'},fontWeight:'950',mt:{xs:3,sm:13},color:'#c7167a'}}>India's Premier<br/> Student Housing<br/> Brand.</Typography>
<Typography variant='h6' sx={{fontSize:{xs:18,sm:26},mt:2,fontWeight:'500',color:'black',textAlign:{xs:'center',sm:'center',md:'left',lg:'left'},}}>Award Winning Student Experience</Typography>

<Card sx={{display:'flex',flexDirection:'row',mt:7,height:60,borderRadius:10,mr:{xs:'0%',sm:'0%',md:'20%',lg:'20%'},borderBottom:5,borderBottomColor:'#f0f0f0',borderTop:1,borderTopColor:'#7b7b7b',justifyContent:'space-between'}}>

<Box sx={{display:'flex',alignItems:'center',ml:2}}>
<LocationOnIcon sx={{color:'#c7167a'}}/>
<Box>
<TextField

         sx={{width:'100%',outline:0,ml:2}}
         placeholder="Search location"
          id="outlined-error"
          variant='standard'
          onChange={this.handleChange}
          value={this.state.city}
          InputProps={{
            disableUnderline:true,
            
          }}
        />
</Box>
</Box>
<Box sx={{display:'flex',alignItems:'center'}}>

<Box onClick={this.mm} sx={{ display:'flex',justifyContent:'center',backgroundColor:'#e1e9e9',height:35,mr:5,width:90,borderRadius:7}}>
<Box sx={{display:'flex',alignItems:'center'}}>
<MyLocationIcon sx={{height:15,width:15,mr:1}}/>

<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{fontSize:12}}>Near me</Typography>
</Box>
</Box>
</Box>

<Box sx={{height:45,width:45,backgroundColor:'#c7167a',display:'flex',justifyContent:'center',borderRadius:7,mr:1}}>
<Box sx={{display:'flex',alignItems:'center'}}>
<SearchIcon sx={{color:'white',display:'flex',alignItems:'center'}}/>
</Box>
</Box>



</Box>
</Card>


     </Box>
    </Grid>
    <Grid item xs={12} sm={12} md={6} lg={6}>
    <Box sx={{minHeight:401,mt:{xs:0,sm:6}}}>
    <Box sx={{display:{xs:'none',sm:'flex'},justifyContent:'right'}}>
     <Horrizontalscroll/>
    </Box>
    <Box sx={{display:{xs:'flex',sm:'none'},justifyContent:'right'}}>
    <Mobilehorrizontal/>
   </Box>
    </Box>
    </Grid>
  </Grid>
    </Box>
    


<Box sx={{backgroundColor:'#f8f6f6',minHeight:100,mt:2,borderRadius:4}}>
<Grid item xs={12} sm={12} md={12} lg={12}>
<br/>
<Box sx={{maxHeight:300}}>
<Card sx={{maxHeight:200,backgroundColor:'white',borderRadius:5,m:2,mb:2}}>
<Typography color='black' align='center' sx={{fontSize:{xs:19,sm:19,md:22},fontFamily:'serif'}} fontWeight='bold' mt={2}>30+ Years Of Legacy </Typography>

<Grid container spacing={1}>
<Grid item xs={4} sm={4} md={4} lg={4}>


<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<LocationCityIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+22</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Cities</Typography>
</Box>
</Box>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4}>
<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<AddHomeIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold'}}>+300</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Residences</Typography>
</Box>
</Box>
</Grid>
<Grid item xs={4} sm={4} md={4} lg={4}>
<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<Groups2Icon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+30,000</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Happy Customers</Typography>
</Box>
</Box>
</Grid>
</Grid>
<br/>
</Card>
<br/>
 </Box>
</Grid>
</Box>









<Box sx={{minHeight:200}}>
<br/>
<Typography variant='h1' sx={{fontSize:{xs:20,sm:22,md:27,lg:27},fontWeight:'600',mt:2,ml:2}}>Popular Accommodation</Typography>
<Box sx={{m:2}}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={tt}
      alt=''
    />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
        Dhiyaar Living - JD Apartment
        </Typography>

        <Box sx={{display:'flex'}}>
 <img src={girl} style={{height:30,width:30,marginLeft:3}}/>

</Box>


        <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
        Rajiv Nagar South, Mhada Colony, Viman Nagar, Pune, Maharashtra 411014
        </Typography>
      </CardContent>
      <CardActions >
    
        <Button size="small" variant='contained' onClick={this.locone} endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Book now</Button>
        <Button size="small" variant='contained' onClick={this.view} endIcon={<LocationOnIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Map</Button>
        
      </CardActions>


    </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={ttt}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
      Dhiyaar Living - Gokul Garden
      </Typography>
      <Box sx={{display:'flex'}}>
 <img src={boy} style={{height:30,width:30,marginLeft:3}}/><img src={girl} style={{height:30,width:30,marginLeft:3}}/>


</Box>

      <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
      Gokul Gardens, Konark Nagar, Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'static', bottom:1}}>
        <Button size="small" variant='contained' onClick={this.loctwo} endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Book now</Button>
        <Button size="small" variant='contained' onClick={this.viewtwo} endIcon={<LocationOnIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Map</Button>
        </CardActions>
    </Card>
      </Grid>
    
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
      
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width:'100%',
      }}
      src={imgone}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
      Dhiyaar Living - Chandare Apartment
      </Typography>

<Box sx={{display:'flex'}}>

<img src={boy} style={{height:30,width:30}}/> 

</Box>


      <Typography variant="body2" color="text.secondary" sx={{mt:1,color:'black',fontWeight:'600'}}>
      Gulmohar Regency Building, No: 7, Dattamandir Chowk, Clover Park, Viman Nagar, Pune, Maharashtra 411014
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'relative',bottom:0}} >
      
        <Button size="small" variant='contained' onClick={this.locthree}  endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Book now</Button>
        <Button size="small" variant='contained' onClick={this.viewthree} endIcon={<LocationOnIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Map</Button>
      </CardActions>
    </Card>
      </Grid>
    </Grid>

    </Box>
</Box>



















<Box sx={{minHeight:200}}>
<br/>
<Typography variant='h1' sx={{fontSize:{xs:20,sm:22,md:27,lg:27},fontWeight:'600',mt:2,ml:2}}>Other Accommodation</Typography>
<Box sx={{m:2}}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={tt}
      alt=''
    />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
        Dhiyaar Living - Mhada
        </Typography>

        <Box sx={{display:'flex'}}>
 <img src={girl} style={{height:30,width:30,marginLeft:3}}/>

</Box>
       

        <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
        Mhada Colony, Viman Nagar, Pune, Maharashtra 411014
        </Typography>
      </CardContent>
      <CardActions >
    
        <Button size="small" variant='contained' onClick={this.locfour} endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Book now</Button>
        <Button size="small" variant='contained' onClick={this.viewfour} endIcon={<LocationOnIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Map</Button>
        
      </CardActions>


    </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={ttt}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
      Dhiyaar Living - Viman Palace
      </Typography>
<Box sx={{display:'flex'}}>
 <img src={girl} style={{height:30,width:30,marginLeft:3}}/>
</Box>
      <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
      HW86+JWV, Konark Nagar, Clover Park, Viman Nagar, Pune, Maharashtra 411014
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'static', bottom:1}}>
        <Button size="small" variant='contained' onClick={this.locfive} endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Book now</Button>
        <Button size="small" variant='contained' onClick={this.viewtwo} endIcon={<LocationOnIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Map</Button>
        </CardActions>
    </Card>
      </Grid>
    
      <Grid item xs={12} sm={12} md={4}>
     
      <Card sx={{height:450,width:'100%' }}>
      <Box
      component="img"
      sx={{
      
        height:220,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width:'100%',
      }}
      src={imgone}
      alt=''
    />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
      PG IN VIMAN NAGAR - PUNE 
      </Typography>

<Box sx={{display:'flex'}}>
 <img src={boy} style={{height:30,width:30,marginLeft:3}}/>

</Box>


      <Typography variant="body2" color="text.secondary" sx={{mt:1,color:'black',fontWeight:'600'}}>
      Dhiyaar-Living Mhada Colony, Viman Nagar, Pune, Maharashtra - 411014
      </Typography>
      </CardContent>
      <CardActions sx={{display:'flex',position:'relative',bottom:0}} >
      
        <Button size="small" variant='contained' onClick={this.locsix}  endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Book now</Button>
        <Button size="small" variant='contained' onClick={this.viewthree} endIcon={<LocationOnIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Map</Button>
      </CardActions>
    </Card>
      </Grid>
    </Grid>

    </Box>
</Box>

<br/>








<Box sx={{display:'flex',flexDirection:'row',overflow:'auto',ml:2,mr:2}}>


<Box sx={{width:'100%'}}>
<Grid container spacing={2}>
{this.state.data?this.state.data.map((w)=>(

      <Grid item xs={12} sm={12} md={3}>
      <Card sx={{height:350,width:'100%' }}>
      <Box
      component="img"
      sx={{
        height:150,
        display: 'block',
      objectFit:'cover',
        overflow: 'hidden',
        width: '100%',
      }}
      src={tt}
      alt=''
    />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{fontSize:18,fontWeight:'800',color:'#c7167a',mt:2}}>
     {w.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{mt:2,color:'black',fontWeight:'600'}}>
      {w.address}
        </Typography>
      </CardContent>
      <CardActions >
        <Button size="small" variant='contained' onClick={this.locone} endIcon={<BookIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Book now</Button>
        <Button size="small" variant='contained' onClick={()=>window.open('https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination='+w.latitude+','+w.longitude+'')} endIcon={<LocationOnIcon  sx={{color:'white'}}/>} sx={{backgroundColor:'#c7167a'}}>Map</Button>
      </CardActions>
    </Card>
      </Grid>

))
:null

}


      </Grid>
</Box>






</Box>














































































<Typography variant='h1' sx={{fontSize:{xs:20,sm:22,md:25,lg:25},fontWeight:'600',mt:2,ml:2,mt:2}}>Our Customers</Typography>
<Box sx={{backgroundColor:'#f5f3f3'}}>
<Scroller/>
</Box>





<br/>


<Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
<Box sx={{ml:{xs:'0%',sm:'2%'},mr:{xs:'0%',sm:'2%'}}}>
<br/>
<Typography align='justify' variant='h1'  sx={{m:1,mb:2,fontWeight:'545',fontFamily:'sans-serif',color:'white',fontSize:{xs:22,sm:22,md:30,lg:30},}}>Who we are ?.</Typography>
<Divider/>
<Typography align='justify'  sx={{m:1,mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>
PG refers to when a person stays in someone else's house/property and pays rent for the accommodation and facilities available with it, such as electricity, water, housekeeping, and food at times.
Dhiyaar living is a fully furnished PG for girls and boy especially designed to suit every need of a modern student. This home away from home allows perfect comfort and safety for you.
We believe that studying away from home should be a stress-free experience. We try to make living away from home safe, exciting and memorable for young adults by providing not just a living space but a space full of endless possibilities!
</Typography>
<Typography align='justify'  sx={{m:1,mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>Dhiyaar living provides a conglomeration of facilities required to conduct the daily routine. Some of the common facilities provided by PG accommodations are washing machine, purified drinking water, geyser, power-backup, security, CCTV camera, meals, tea/ coffee, and daily cleaning service.</Typography>
<Typography align='justify'  sx={{m:1,mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:13,sm:13,md:15,lg:15}}}>We are committed to providing parents a secure and comfortable living for their children. We aspire to provide our students a holistic living experience in a friendly and inclusive community.</Typography>
<br/>


</Box>
</Box>







</Container>
</Box>
      </div>
    )
  }
}



export function Pageonec(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Pageone navigate={navigate}></Pageone>)
}





export default Pageone