import React, { Component } from 'react'
import Box from '@mui/material/Box';
import computer from "../src/Imgforp/mobileee.png"
import cc from "../src/Imgforp/computer.png"
import doctor from "../src/Imgforp/doctor.png"
import CircleIcon from '@mui/icons-material/Circle';
import { Container, Toolbar,Grid, Card,Typography,Divider } from '@mui/material';
export class Covid extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'pink',mt:6,display:{xs:'none',sm:'none',md:'block'}}}>
      <img
      className="d-block w-100"
      src={computer}
      alt="Second slide"
      style={{objectFit:'contain',width:'100vh'}}
    />
      </Box>
      
      <Box sx={{backgroundColor:'pink',mt:6,display:{xs:'block',sm:'block',md:'none'}}}>
      <img
      className="d-block w-100"
      src={cc}
      alt="Second slide"
      style={{objectFit:'contain',width:'100vh'}}
    />
      </Box>

     
<Box sx={{backgroundColor:'#f1f4f6',minHeight:300}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
      <Container maxWidth='xl'>
      <br/>
<Box>
<Typography align='justify'  sx={{mb:2,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>The covid-19 pandemic has brought health and hygiene to the forefront. As one of India’s most trusted student hostels, we have adopted covid safety protocols in all our hostels to ensure the health and safety of our students.</Typography>
 <Divider/>
 <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#c7167a",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Daily deep cleaning and sanitization of rooms and common areas.</Typography>
    </Box>
   

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#c7167a",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Health insurance of 1 lakh INR for all Dhiyaar Living students.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#c7167a",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Fully vaccinated staff and employees.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#c7167a",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Isolation and covid quarantine protocols in place in cases of covid-19 breakouts.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#c7167a",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Compulsory wearing of masks and social distancing in common areas.</Typography>
    </Box>

    <Box sx={{display:'flex',flexDirection:'row',m:2,justifyContent:'left'}} >
    <CircleIcon sx={{height:10,width:10,color:"#c7167a",mt:0.7}}/>
    <Typography align='justify' sx={{marginRight:2,marginLeft:1,fontFamily: 'inherit',fontWeight:'700',color:'#00337b',fontSize:{xs:13,sm:13,md:15}}}>Dhiyaar Living tie-up with a home health care service provider for covid testing as and when required.</Typography>
    </Box>

<br/>
</Box>
</Container>
</Box>
</Box>


      
      </div>
    )
  }
}

export default Covid