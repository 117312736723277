import firebase from 'firebase/compat/app';
import "firebase/compat/auth";

import 'firebase/compat/storage';
import "firebase/compat/database";

const firebaseConfig = {
    apiKey: "AIzaSyC1BJHhKgdYEgd8uzrXrYEWTO7x_75L8rY",
    authDomain: "dhiyaar-living.firebaseapp.com",
    projectId: "dhiyaar-living",
    storageBucket: "dhiyaar-living.appspot.com",
    messagingSenderId: "1094679610421",
    appId: "1:1094679610421:web:921f47cca67e6867641d7c",
    measurementId: "G-YRD0QPT7CH"
  };
firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();

export const storage = firebase.storage();
export const database = firebase.database();

 
export default firebase;






