import React, { Component } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Box ,Link,Typography} from '@mui/material';



const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14}}>
    Privay-policy
    </Typography>,
  ];


export class Privacy extends Component {


  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'#f1f4f6'}}>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
      <Container maxWidth='xl'>
      <Box sx={{minHeight:300,mt:6}}>
<br/>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
<br/>
    <Typography variant='h1' sx={{fontSize:27,fontFamily:'sans-serif',fontWeight:'800'}}>Privacy Policy</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>Our privacy is important to us and Ashimara Housing Private Ltd (‘We’ or ‘AHPL’) is committed to protect the same. We have adopted the following Privacy Policy, which is applicable to all personally identifiable information (‘PII’) that you provide to AHPL through www.your-space.in (the "Website") and [·] (the “Application”). You must also review and accept our Website & Application Terms of Use as it is also applicable and govern your use of the Website and Application. This Privacy Policy does not apply to any PII that you may provide to third parties, such as through other sites linked to the Website and Application. Please be aware that if you choose to visit such third party sites, you should contact such third parties directly to determine their respective privacy policies because that third party's privacy policy and not our Website's and Application’s will govern your activities and any PII you disclose while visiting the third party site. This privacy policy explains the purposes for which AHPL processes your PII through its Website and Application.</Typography> 

    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>PURPOSE OF COLLECTION AND PROCESSING</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>We may collect your title, name, gender, date of birth, email address, mobile number, location and other personally identifiable information that you submit on the Website and Application ("Personally Identifiable Information") to provide you: - a smooth, efficient and customized experience; - to enable us to reach out to you in relation to services offered by us; - to enable us to improve our services; - to process your requests (such as replying to your queries or complaints); - to evaluate your candidature for prospective career opportunities with us; - to drive marketing campaign, promotional communications for which you have consented; - to protect our vital interest The PII collected for the aforesaid purposes will not be posted in areas of the Website and Application viewable by the public or users of the site..</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>How We Collect Your Personal Data:</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>We collect Data in the following ways: -PII is given to us by you; and -PII is collected automatically pursuant to your visit to the Website and Application..</Typography> 

    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>Data shared by You</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>AHPL may collect your PII in several ways from your use of this Website and Application, for instance: - when you contact us through the Website or Application (through any form vis. e-mail address, hyperlink or social media address posted therein); - when you register with us to receive our services; - when you complete surveys conducted by or for us; - when you elect to receive any communications (including any newsletter, promotional offers) from us and - information as may be voluntarily provided by you Data that is Collected Automatically We automatically collect some information about your visit to the Website and Application. This information helps us to make improvements to Website and Application content and navigation and includes your IP address and the way you use and interact with its content, namely: -  Our web servers or affiliates who provide analytics and performance enhancement services collect IP address, operating system details, browsing details, device details and language settings. This information is aggregated to measure the number of visits, average time spent on the site, pages viewed and similar information. We use this information to measure the site usage, improve content and to ensure safety and security, as well enhance performance of the Website. - We may collect your PII automatically via Cookies, in line with the cookie settings on your browser. For more information about Cookies, please see the section below, titled “Cookies”.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>Use of Cookies</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>This Website and Application may place and access certain Cookies on your computer, mobile and uses them to improve your experience of using the Website and Application. Before the Website and Application places cookies on your computer, mobile, you will be presented with a message bar requesting your consent to set those Cookies. You may, if you wish, deny consent to the placing of Cookies; however certain features of the Website may not function fully or as-intended. You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies, but this can be changed. For further details, please consult the help menu in your internet browser.</Typography> 

    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>TRANSFER OF PERSONALLY IDENTIFIABLE INFORMATION TO ADITYA BIRLA GROUP ENTITIES</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>The Website and the Application is designed to give you one stop view of Ashimara Housing Private Limited and enable you to opt for services, interact on the community, raise queries related to any services being offered by us. To enable us to provide you seamless experience, we may have to share your PII with our group companies and also with some of our vendors and service providers. To the extent that these entities have access to your PII, we will ask them to treat it at least as protectively as they treat personally identifiable information they obtain from their other authorized sources.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>TRANSFER OF PERSONALLY IDENTIFIABLE INFORMATION</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>The PII that we collect from you may be transferred to and stored at locations that may or not be in India. It may also be processed by staff operating outside the territory of India, who work for or are engaged by us.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>LINKS TO OTHER WEBSITES</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>Our Website and Application has links that redirects you to our social networking pages such of Facebook, Twitter, LinkedIn, YouTube and others that may be added from time to time. Your visits and activity thereon vis-à-vis those portals shall be governed by their own privacy policies applicable for visiting their sites. Please go through their privacy policies to get information on their Privacy Practices.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>PARTNER</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>To enable us to provide you enhanced and secured user experience, we may enter into contract with various third party vendors from time to time. Such engagement may involve sharing of your PII. To the extent a third party has access to your PII, we will ask them to treat it at least as protectively as they treat personally identifiable information they obtain from their other authorized sources. We do not market or sell your personal data to any third party.</Typography> 

    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>DATA RETENTION POLICY</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>We will retain data for a reasonable time and/or as may be statutorily required to be maintained as per applicable law/ or arising out of legitimate purposes. We shall take reasonable steps to destroy or permanently de-identify PII that is no longer needed, including that processed by third party vendors engaged by the Company from time to time.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>CHANGING YOUR PERSONALLY IDENTIFIABLE INFORMATION</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>We give you the ability to edit your PII at any time, a request thereto should be made to [·]. We will, however, retain in our files and databases PII you have requested to remove in order to use it in some circumstances, such as to resolve disputes, troubleshoot problems, and enforce our Terms and Conditions of Use. Further, such prior PII is never completely removed from our databases due to technical and legal constraints, including stored "back up" systems. Therefore, you should not expect that all of your PII will be completely removed from our databases in response to your requests.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>REGULATORY OBLIGATIONS</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>Due to the existing regulatory environment, we cannot ensure that all of your Personal Information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting the foregoing), we may be required to disclose your PII to the government, law enforcement agencies, etc.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>DATA SECURITY</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>To protect your privacy and security, we will also take reasonable steps to verify your identity before granting access. We are committed to protecting your PII in our custody and have taken reasonable steps to ensure appropriate physical, technical and managerial safeguards are in place to protect your Personal Data from unauthorized access, alteration, transmission and deletion. We use encryption at relevant points to protect your data. We have a process for regularly testing the effectiveness of our technical and organizational measures for ensuring the security of the processing.</Typography> 

    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>DISCLAIMER</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>To protect your privacy, as stated above, we will take best efforts and adopt best Industry Standards Information Practice, however you understand that 100% security does not exist over internet. You are responsible for protecting your PII and you should not share your login credentials and/or leave your personal devices unattended while you are accessing the Website, Application etc. The Company does not guarantee and you should not expect in the event of malware attack or negligence on your part that your Personal Information will remain private.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>YOUR CONSENT</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>By accepting this Privacy Policy and our Terms, you expressly consent to our use and disclosure of your Personal Information in the manner prescribed in this Privacy Policy.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>RIGHTS UNDER APPLICABLE LAWS:</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>We are processing your personal data basis the consent you have provided by accepting this Privacy Policy. We acknowledge your right to access, rectification, erasure, restriction of the processing, data portability and object to the processing of your personal data. However, these rights are not absolute in nature and are subjected to contract, applicable legislation, record keeping requirement under applicable statutes, ongoing disputes or potential disputes we may foresee. To exercise these rights with respect to your personal data you may contact info@your-space.in In the event of any misconduct/fraud/cybercrime, etc. on your part, the Company shall be at liberty to use your PII as evidence in the Court of law and shall also be entitled to carry out investigations/inquiries as it deems fit with the help of private agencies or authorities as it deems fit. By you consenting to this Notice, you agree to sharing of your PII for the aforesaid purposes and understand that exercise of your rights to that extent shall be restricted.</Typography> 


    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>QUESTIONS AND GRIEVANCES/ RIGHT TO LODGE A COMPLAINT</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>In the event, you are of the view that your personal data is not processed in accordance with the purposes as disclosed in this Privacy Policy or if you have any query related to your privacy of information or in relation to your rights under applicable law, please feel free to contact us at: info@your-space.in</Typography> 

    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>NOTIFICATION OF CHANGES</Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>We expressly reserves at its sole discretion the right to change this Privacy Policy periodically in the future without prior notice to the Users. User shall review our Privacy Policy regularly to be sure you are fully updated with our practices and procedures in this area.</Typography> 

    <Typography variant='h1' sx={{fontSize:23,fontFamily:'sans-serif',fontWeight:'900'}}>Questions and updates
    </Typography> 
    <Typography variant='body1' align='justify' sx={{fontSize:15,fontFamily:'serif',fontWeight:'500',mt:1,mb:2}}>If you have any questions or suggestions about our privacy practices, please feel free to contact us at: info@your-space.in</Typography> 


<br/>
      </Box>
      </Container>
      </Box>
      </Box>
      </div>
    )
  }
}

export default Privacy