import React, { Component } from 'react'
import poo from "../src/Imgforp/pp.jpg"
import mm from "../src/Imgforp/18939.jpg"
import { Paper,Box,Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';
import bb from "../src/Imgforp/bb.webp"
import AdjustIcon from '@mui/icons-material/Adjust';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import pattern from "../src/Imgforp/pattern.png"
import imgf from "../src/Imgforp/imgf.jpg"
import map2 from "../src/Imgforp/ggg.png"
import snp from "../src/Imgforp/snp.webp"

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    career
    </Typography>,
  ];

export class About extends Component {
  render() {
    return (
      <div>
      <br/>
      <br/>
      <Box sx={{backgroundImage:{xs:`url(${poo})`,sm:`url(${poo})`},width:'100%',display:'flex',height:{xs:200,sm:250,md:310},backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
      <br/>
      <br/>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box sx={{background:'rgba(28,68,106,.7)',height:{xs:140,sm:180,md:200}, width:'100%',mt:1,}}>
      <Box sx={{mt:2}}>
      <br/>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>ABOUT THE DHIYAAR</Typography>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>LIVING</Typography>
      </Box>


      <Box sx={{display:'flex',justifyContent:'center',mt:{xs:2,sm:2,md:5}}}>
      <Box sx={{backgroundColor:'#c7167a',height:40,width:150,borderRadius:10,}}>
<Box sx={{display:'flex',justifyContent:'center'}} >
<Box>
<Typography sx={{mt:1,color:'white'}}>Learn</Typography>
</Box>
</Box>
      </Box>
      </Box>
      </Box>
      </Container>
      </Box> 
 </Box>




 <Box sx={{minHeight:300,backgroundColor:'#e8eeee ',opacity:0.9,backgroundRepeat:''}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
 <Box sx={{mt:1,mb:1,minHeight:300,}}>
 
 <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{display:'flex',flexDirection:'column'}}>
  <br/>
  <Typography variant='h1'  sx={{fontSize:{xs:25,sm:25,md:34},fontWeight:'800',fontFamily:'sans-serif'}}>We didn't find it for us,</Typography>
  <Typography variant='h1'  sx={{fontSize:{xs:25,sm:25,md:34},fontWeight:'800',mt:1,fontFamily:'sans-serif',color:'#c7167a '}}>so we created it for you</Typography>
  <br/>
  <Typography align='justify' sx={{mb:2,mr:{xs:'0',sm:'0',md:'10%',},fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:13,sm:13,md:17,lg:17},lineHeight:2}}>That's essentially our story in one sentence.
  Crammed up hostels or cooped up PGs - not much of a choice, is it? Thats why we created Dhiyaar Living - a place designed by people who've been in your shoes. Understand you. And are inspired by you.</Typography>
  </Box>
  
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>

  <Box sx={{m:{xs:'0',sm:'0',md:'2'}}}>
  <Box sx={{borderRadius:10,height:370,}}>
  <Box
     component="img"
     sx={{
       height:370,
       display: 'block',
     objectFit:'cover',
       overflow: 'hidden',
       width:'100%',
       borderRadius:10
     }}
     src={imgf}
     alt=''
   />
  </Box>
  </Box>



    
  </Grid>
</Grid>
 </Box>
 <br/>
 </Container>
</Box>
 </Box>








 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
   <Box sx={{minHeight:250,}}>

   <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>We believe that studying away from home should be a stress-free experience. We try to make living away from home safe, exciting and memorable for young adults by providing not just a living space but a space full of endless possibilities!</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>India's most trusted hostel and PG service provider in pune the DHIYAAR LIVING. We provide all luxurious service also available in hostel. </Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>DHIYAAR LIVING is  a place designed by people who've been in your shoes. Understand you. And are inspired by you.</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>We put together a list of places where our guests can stay as a PG. This is done based on listings on our site by homeowners. We ensure that not only are they a safe home for the guest but the guest too is safe for them.</Typography>
</Box>
</Box>



   </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{minHeight:250,}}>

  <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Our vision is that to create a trusted and recognized brand of quality student living and enriching student experience Pan India.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Our mission is that we are committed to providing parents a secure and comfortable living for their children. We aspire to provide our students a holistic living experience in a friendly and inclusive community. </Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>We are a set of well-selected and chosen Paying Guest services. This is a platform where those, who are willing to open their homes to guests, meet the people looking for wonderful homes to stay in and not have to look for hotels or favors in any city for their long stays.</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>We ensure the places listed and the people looking for a stay are selected based on careful filtering criteria so that both parties benefit and the safety of all involved persons is ensured.</Typography>
</Box>
</Box>


  </Box>
  </Grid>
 </Grid>
 
 </Container>
 </Box>
 <br/>
  </Box>






 




<Box>
 <Box sx={{minHeight:400,backgroundColor:'#f4f8f8'}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
<Box>
<br/>
<Typography  align='center' variant='h1' sx={{fontSize:{xs:30,sm:30,md:40},fontWeight:'bold'}}>Management Team</Typography>
<br/>
<Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 7 }}>
  <Grid item xs={12} sm={12} md={4} lg={4}>
   <Card sx={{backgroundColor:'white',height:700,borderRadius:6}}>
   <Box sx={{borderRadius:5,height:380,backgroundColor:'#ed73a3'}}>
   <Box
      component="img"
      sx={{
        height:380,
        display: 'block',
      objectFit:'contain',
        overflow: 'hidden',
        width:'100%',
        borderRadius:10
      }}
      src={snp}
      alt=''
    />
   </Box>
  
   <Typography variant='h1' sx={{fontSize:21,color:'#c7167a',m:2,fontWeight:'bold'}}>Nitish kumar</Typography>
   <Typography variant='h1' sx={{fontSize:22,color:'black',m:2,fontWeight:'700',fontFamily:'sans-serif'}}>CEO</Typography>
   
   <Box sx={{display:'flex',flexDirection:'row',ml:2}}>
   <AdjustIcon sx={{height:15,width:15,mr:1,color:'#c7167a',mt:0.4}}/>
   <Box sx={{display:'flex',alignItems:'center'}}>
   <Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Mathematics Hons. St. Stephens College, New Delhi (Gold Medallist)</Typography>
   </Box>
   </Box>

   <Box sx={{display:'flex',flexDirection:'row',ml:2}}>
   <AdjustIcon sx={{height:15,width:15,mr:1,color:'#c7167a',mt:0.4}}/>
   <Box sx={{display:'flex',alignItems:'center'}}>
   <Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Mathematics Hons. St. Stephens College, New Delhi (Gold Medallist)</Typography>
   </Box>
   </Box>





   </Card>
  </Grid>
  <Grid item xs={12} sm={12} md={4} lg={4}>
  <Card sx={{backgroundColor:'white',height:700,borderRadius:6}}>
  <Box sx={{borderRadius:5,height:380,backgroundColor:'#ed73a3'}}>
  <Box
     component="img"
     sx={{
       height:380,
       display: 'block',
     objectFit:'contain',
       overflow: 'hidden',
       width:'100%',
       borderRadius:10
     }}
     src={snp}
     alt=''
   />
  </Box>
 
  <Typography variant='h1' sx={{fontSize:21,color:'#c7167a',m:2,fontWeight:'bold'}}>Nitish kumar</Typography>
  <Typography variant='h1' sx={{fontSize:22,color:'black',m:2,fontWeight:'700',fontFamily:'sans-serif'}}>COO</Typography>
  
  <Box sx={{display:'flex',flexDirection:'row',ml:2}}>
  <AdjustIcon sx={{height:15,width:15,mr:1,color:'#c7167a',mt:0.4}}/>
  <Box sx={{display:'flex',alignItems:'center'}}>
  <Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Mathematics Hons. St. Stephens College, New Delhi (Gold Medallist)</Typography>
  </Box>
  </Box>

  <Box sx={{display:'flex',flexDirection:'row',ml:2}}>
  <AdjustIcon sx={{height:15,width:15,mr:1,color:'#c7167a',mt:0.4}}/>
  <Box sx={{display:'flex',alignItems:'center'}}>
  <Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Mathematics Hons. St. Stephens College, New Delhi (Gold Medallist)</Typography>
  </Box>
  </Box>





  </Card>
  </Grid>
  <Grid item xs={12} sm={12} md={4} lg={4}>
  <Card sx={{backgroundColor:'white',height:700,borderRadius:6}}>
  <Box sx={{borderRadius:5,height:380,backgroundColor:'#ed73a3'}}>
  <Box
     component="img"
     sx={{
       height:380,
       display: 'block',
     objectFit:'contain',
       overflow: 'hidden',
       width:'100%',
       borderRadius:10
     }}
     src={snp}
     alt=''
   />
  </Box>
 
  <Typography variant='h1' sx={{fontSize:21,color:'#c7167a',m:2,fontWeight:'bold'}}>Nitish kumar</Typography>
  <Typography variant='h1' sx={{fontSize:22,color:'black',m:2,fontWeight:'700',fontFamily:'sans-serif'}}>CHIEF REVENUE OFFICER</Typography>
  
  <Box sx={{display:'flex',flexDirection:'row',ml:2}}>
  <AdjustIcon sx={{height:15,width:15,mr:1,color:'#c7167a',mt:0.4}}/>
  <Box sx={{display:'flex',alignItems:'center'}}>
  <Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Mathematics Hons. St. Stephens College, New Delhi (Gold Medallist)</Typography>
  </Box>
  </Box>

  <Box sx={{display:'flex',flexDirection:'row',ml:2}}>
  <AdjustIcon sx={{height:15,width:15,mr:1,color:'#c7167a',mt:0.4}}/>
  <Box sx={{display:'flex',alignItems:'center'}}>
  <Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Mathematics Hons. St. Stephens College, New Delhi (Gold Medallist)</Typography>
  </Box>
  </Box>





  </Card>
  </Grid>
</Grid>
</Box>
</Container>
</Box>
</Box>
</Box>





      
      </div>
    )
  }
}

export default About








