import React, { Component } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Divider,Button } from '@mui/material';
import logo from "../src/logo.png"
import PersonIcon from '@mui/icons-material/Person';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import Pageone, { Pageonec } from './Pageone';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './Footer';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Covid from './Covid';
import Privacy from './Privacy';
import Termcondition from './Termcondition';
import Faqs from './Faqs';
import Career from './Career';
import About from './About';
import Investor from './Investor';
import Schlorship from './Schlorship';
import Media from './Media';
import Blogs, { Blogc } from './Blogs';
import Funtest from './Funtest';
import Form, { Formc } from './Form';
import Cheakout, { Cheakoutc } from './Cheakout';
import Bill from './Bill';
import Locationone from './Locationone';
import Locationtwo from './Locationtwo';
import Locationthree from './Locationthree';
import { Loginc } from './Login';
import { Registerc } from './Register';
import Resetpassword from './Resetpassword';
import Bookedroom, { Bmc } from "../src/Bookedroom"
import NavigationIcon from '@mui/icons-material/Navigation';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Admin from './Admin';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Pageonec/>,
  },
  {
    path: "/covide",
    element: <Covid/>,
  },
  {
    path: "/privacy",
    element: <Privacy/>,
  },
  {
    path: "/Termcondition",
    element: <Termcondition/>,
  },
  {
    path: "/faqs",
    element: <Faqs/>,
  },
  {
    path: "/career",
    element: <Career/>,
  },
  {
    path: "/about",
    element: <About/>,
  },
  {
    path: "/investor",
    element: <Investor/>,
  },

  {
    path: "/Schlorship",
    element: <Schlorship/>,
  },
  {
    path: "/Mhedia",
    element: <Media/>,
  },
  {
    path: "/blogc",
    element: <Blogc/>,
  },

  {
    path: "/funtest",
    element:<Funtest/>,
  },
  {
    path: "/formc",
    element:<Formc/>,
  },
  {
    path: "/cheakoutc",
    element:<Cheakoutc/>,
  },

  {
    path: "/bill",
    element:<Bill/>,
  },
  {
    path: "/locationone",
    element:<Locationone/>,
  },
  {
    path: "/locationtwo",
    element:<Locationtwo/>,
  },
  {
    path: "/locationthree",
    element:<Locationthree/>,
  },
  {
    path: "/login",
    element:<Loginc/>,
  },
  {
    path: "/register",
    element:<Registerc/>,
  },
  {
    path: "/resetpassword",
    element:<Resetpassword/>,
  },
  {
    path: "/login/bookedroom",
    element:<Bmc/>,
  },

  {
    path: "/Admin",
    element:<Admin/>,
  },

]);


export class Main extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      anchorEl : null,
      open: false
    }
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick(event) {
    this.setAnchorEl(event.currentTarget);
}
    setAnchorEl(value){
        this.setState({
            anchorEl: value,
            open: !this.state.open
        })
    }
handleClose() {
    this.setAnchorEl(null);
}


openi=()=>{
  window.open("/Admin")
}



renderMenu(){
  return(
    <Box sx={{}}>
  <Menu id="fade-menu" anchorEl={this.state.anchorEl} open={this.state.open}  onClose={this.handleClose}>
       
        <MenuItem component='a' href='/login' onClick={this.handleClose}>Login</MenuItem>
        <MenuItem component='a' href='/register' onClick={this.handleClose}>Register</MenuItem>
        <MenuItem component='a' href='/login/bookedroom' onClick={this.handleClose}>Your Booking </MenuItem> 
        <MenuItem component='a' href='/' onClick={this.handleClose} >Your PG</MenuItem>
         <MenuItem component='a' href='/blogc' onClick={this.handleClose} >Blogs</MenuItem>

        <MenuItem component='a' href='/about' onClick={this.handleClose} >About Us</MenuItem>
 <MenuItem component='a'  onClick={this.openi} >Admin</MenuItem>

    </Menu>
    </Box>
   )
}

  render() {
    return (
      <div>
      <Box>
      
      <AppBar position="fixed" sx={{backgroundColor:'#ffffff',borderBottom:2,borderBottomColor:'#c7167a'}}>
       <Container maxWidth='xl'>
      <Toolbar variant="dense">
     
       <Box sx={{flex:1,marginLeft:{xs:-3,sm:0,md:0,lg:0}}}>
       <img alt='logo' src={logo} style={{height:55,objectFit:'contain'}}/>
       </Box> 



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'right'}}>
<Box sx={{display:'flex',alignItems:'center'}}>


<Box sx={{display:'flex',justifyContent:'center'}}>

<IconButton  sx={{ mr: 1, height:'50',width:'50'}}>
<AddIcCallIcon  sx={{color:'black',height:'50',width:'50'}}/>
</IconButton>
<Box sx={{display:{xs:'none',sm:'flex',md:"flex",lg:'flex'},alignItems:'center',}}>
<Typography sx={{color:'black',mr:5,fontWeight:'bold',fontSize:20,fontFamily:'serif'}}>+91-9158000375</Typography>
</Box>
</Box>



<IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 3 }}>
<PersonIcon sx={{color:'black',height:'60',width:'50'}}/>
</IconButton>

<IconButton edge="start" color="inherit" aria-label="menu" sx={{marginRight:{xs:-3,sm:0,md:0,lg:0}  }} aria-owns={this.state.open ? 'fade-menu' : undefined} aria-haspopup="true" onClick={this.handleClick}>
<MenuIcon sx={{color:'black',height:'60',width:'50'}}/> 
</IconButton>
{this.renderMenu()}





</Box>
</Box>
      </Toolbar> 
      </Container>
    </AppBar>
     <Divider sx={{borderBottomWidth:6}}/>

     <Box>
    


     <React.StrictMode>
     <RouterProvider router={router} />
   </React.StrictMode>

   <Box sx={{display:'flex',justifyContent:'right'}} component='a' href="https://wa.me/919158000375">
   <Fab variant="extended" size="medium"  aria-label="add"  sx={{position:"fixed",bottom:20,right:10,color:'white'}}>
<WhatsAppIcon sx={{ mr: 1,color:'#00337b' }} />
<Typography color='#00337b'>WhatsApp</Typography>
</Fab>
   </Box>
 
   </Box>

<Box>
<Footer/>
</Box>


 </Box>
      </div>
    )
  }
}

export default Main