import React, { Component } from 'react'
import { Box ,Grid,TextField,Button, Divider} from '@mui/material'
import firebase from "../firebase"
export class Add extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        name:"",
        address : "",
        latitude : "",
        longitude : "",
        file:"",
        double_premium_bed:"",
        double_bed:"",
        single_bed:"",
      }
      this.handleChange=this.handleChange.bind()
      this.handleChangee=this.handleChangee.bind()
    }
handleChange=(e)=>{
this.setState({[e.target.name]:e.target.value})
}

handleChangee=(e)=>{
  this.setState({[e.target.name]:e.target.files[0]})
}

Submit=()=>{
if(this.state.file.size<=100000){
  const collegekeyyy= firebase.database().ref("collegyyyhe").push().key

  var storageRef = firebase.storage().ref();
  var uploadTask = storageRef.child(collegekeyyy).put(this.state.file);


  // 3. Completion observer, called on successful completion
  uploadTask.on('state_changed', 
    (snapshot) => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // Handle unsuccessful uploads
    }, 
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
       // console.log('File available at', downloadURL);

        if(this.state.address!==""&&this.state.file!=="" &&this.state.latitude!=="" &&this.state.longitude!=="")
       {
        const collegekeyyc= firebase.database().ref("collegyywlkelkyheprojec tkey").push().key
  firebase.database().ref("other_pg").child(collegekeyyc).set({
      name:this.state.name,
      id:collegekeyyc,
      address:this.state.address,
     latitude:this.state.latitude,
     longitude:this.state.longitude,
     img:downloadURL
  }).then(()=>{
    alert("successful")
  })
       }else{
        alert("All fields are complessary")
       }

      });
    }
  );
  
}else{
  alert("File size is too big allow below 1 MB file")
}
}


double_premium_bed=()=>{
if(this.state.double_premium_bed!==""){
  firebase.database().ref('double_premium_bed').set({
   price: this.state.double_premium_bed,
  }).then(()=>{
    alert("setting done")
  })

}else{
  alert("fill for price")
}
  
}




single_bed=()=>{
  if(this.state.single_bed!==""){
    firebase.database().ref('single_bed').set({
     price: this.state.single_bed,
    }).then(()=>{
      alert("setting done")
    })
  
  }else{
    alert("fill for price")
  }
    
  }


  double_bed=()=>{
    if(this.state.double_bed!==""){
      firebase.database().ref('double_bed').set({
       price: this.state.double_bed,
      }).then(()=>{
        alert("setting done")
      })
    
    }else{
      alert("fill for price")
    }
      
    }




  render() {
    console.log(this.state.file,this.state.name,this.state.address,this.state.longitude,this.state.latitude)
    return (
      <div>
<Box sx={{mt:-4,m:2}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" fullWidth size='small' label=" Enter pg name" name="name" variant="outlined" onChange={this.handleChange} />
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" fullWidth size='small' label="Enter pg address" name="address" variant="outlined" onChange={this.handleChange}/>
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" fullWidth size='small' label="Enter Latitude" name="latitude" variant="outlined" onChange={this.handleChange}/>
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" label="Enter Longitude" fullWidth size='small' variant="outlined" name="longitude" onChange={this.handleChange} />
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
    <Box sx={{border:1}}>
       <input type='file' name='file' onChange={this.handleChangee} />
    </Box>
  </Grid>
</Grid>
<br/>

<Button variant="contained" onClick={this.Submit} size='small' disableElevation sx={{textTransform:'none'}}>Submit</Button>
</Box>

<br/>
<Divider/>
<Divider/>
<br/>
<br/>
<Box sx={{display:'flex',flexDirection:'row',ml:4}}>
<TextField id="outlined-basic" name="double_premium_bed" sx={{maxWidth:250}} label="price for double premium bed" fullWidth size='small' variant="outlined"  onChange={this.handleChange} />
<Button variant='contained' sx={{ml:2}} onClick={this.double_premium_bed}>submit</Button>
</Box>
<br/>
<Box sx={{display:'flex',flexDirection:'row',ml:4}}>
<TextField id="outlined-basic" name="single_bed" label="price for single bed" sx={{maxWidth:250}}  fullWidth size='small' variant="outlined" onChange={this.handleChange} />
<Button variant='contained' sx={{ml:2}} onClick={this.single_bed} >submit</Button>
</Box>
<br/>
<Box sx={{display:'flex',flexDirection:'row',ml:4}}>
<TextField id="outlined-basic" name="double_bed" label="price for double  bed" sx={{maxWidth:250}} fullWidth size='small' variant="outlined" onChange={this.handleChange} />
<Button variant='contained' sx={{ml:2}} onClick={this.double_bed}>submit</Button>
</Box>



      </div>
    )
  }
}

export default Add