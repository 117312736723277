import React, { Component } from 'react'
import desktop from "../src/img/desktop.png"
import {Box, Container, Typography} from "@mui/material"
import AdjustIcon from '@mui/icons-material/Adjust';
export class Locationone extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'pink',mt:6,display:{xs:'block',sm:'block',md:'block'}}}>
      <img
      className="d-block w-100"
      src={desktop}
      alt="Second slide"
      style={{objectFit:'contain',width:'100vh'}}
    />
      </Box>
      <Box sx={{backgroundColor:'#fff',minHeight:400}}>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
      <Container maxWidth='xl'>
      <Box sx={{mt:2}}>
      <Typography variant='h1' sx={{fontSize:30,fontWeight:'900',color:'#c7167a'}}>PG in Pune</Typography>
      <br/>
      <br/>
      <Box sx={{display:'flex',flexDirection:'row',mb:2}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>If you are looking to find accommodation at the educational hub of the country, Dhiyaar living is the place to be! Dhiyaar living provides specially designed comfortable PGs and a hostel in Pune for students and working professionals. There are flats in Pune for students & working professionals, but they tend to get heavy on the pockets. At Dhiyaar living, you get everything in one place, from the best stays to healthy food and so it is counted among the luxurious PG in Pune. It is hassle-free living within your budget!</Typography>
</Box>
</Box>




<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Pune is a hub for educational institutes and colleges and is rightly called the Oxford of the East. Pune has many well-known colleges and institutes such as Fergusson College, Symbiosis University, DY Patil University, FTII, etc. The city, due to its pleasant weather and host of opportunities gets a massive number of people moving here every year and therefore, you will find many various women's hostels in Pune. With students coming to this city with dreams of studying in top institutes, their safety is a big worry for their parents. Dhiyaar living has safety measures like 24x7 CCTV cameras, biometric scans and security guards on every property making it a perfect choice for every student.
</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}> Dhiyaar living provides low-cost student PG in Pune without compromising on the quality so that students can have the best time of college without worrying about high rents.
</Typography>
</Box>
</Box>






      </Box>
      </Container>
      </Box>
      </Box>






      
      
      
      </div>
    )
  }
}

export default Locationone