import React, { Component } from 'react'
import { Container,Typography,Card,Grid,Divider,TextField,Button,Box,Link} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DeleteIcon from '@mui/icons-material/Delete';
import AdjustIcon from '@mui/icons-material/Adjust';
import single from "../src/img/single.png"
import dd from "../src/Imgforp/dd.png"
import firebase from "../src/firebase"
import { useNavigate,useLocation } from 'react-router-dom';
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
  Form
    </Typography>,
  ];

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
   // border: `1px solid ${theme.palette.divider}`,
    //'&:not(:last-child)': {
    //  borderBottom: 0,
   // },
   // '&:before': {
    //  display: 'none',
    //},
    mb:1
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', }} />}
      {...props}
    />
  ))(({ theme }) => ({
   // backgroundColor:
   //   theme.palette.mode === 'dark'
     //   ? 'rgb(224,245,254)'
       // : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  



export class Form extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       first:"",
       expanded:'panel1',
       amount:"",
       bed:'Double-Premium Beds',
       location:"",
       double_bed_amount:"",
       single_bed_amount:"",
       double_bed_premium_amount:"",
    }
  }


componentDidMount(){
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {

    }else{
this.props.navigate("/login")
    }
  })
  const location = localStorage.getItem("location");
  this.setState({location:location});
  


  firebase.database().ref().child('double_bed').get().then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val().price);
      this.setState({double_bed_amount:snapshot.val().price})
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });



  firebase.database().ref().child('double_premium_bed').get().then((snapshot) => {
    if (snapshot.exists()) {
      this.setState({double_bed_premium_amount:snapshot.val().price,amount:snapshot.val().price})
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });



  firebase.database().ref().child('single_bed').get().then((snapshot) => {
    if (snapshot.exists()) {
      this.setState({single_bed_amount:snapshot.val().price})
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });


}

cheakout=()=>{
 
      this.props.navigate('/cheakoutc')
  localStorage.setItem("bed",this.state.bed)
  localStorage.setItem("amount", this.state.amount)
   

  
  
}



  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'#f1f4f6',minHeight:600}}>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box>
  <br/>
  <br/>
  <br/>
     
       <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 9 }}>
       <Grid item xs={12} sm={12} md={8}>
       <Box>
       <Typography variant='h1' sx={{fontSize:28,fontWeight:'600',fontFamily:'sans-serif'}}>PG in {this.state.location}</Typography>
       <br/>
<Box sx={{mb:0.7}}>
<Accordion expanded={this.state.expanded === 'panel1'} onChange={()=>{this.setState({expanded:'panel1'})}}>
<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:this.state.expanded==='panel1'?"#f1f4f6":"white"}}>
  <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>
  Available Occupancies
</Typography>
</AccordionSummary>
<AccordionDetails sx={{backgroundColor:'#f1f4f6'}}>
 <Box sx={{minHeight:200,}}>

 <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
  <Grid item xs={12} sm={12} md={5}>
  <Box onClick={()=>this.setState({bed:'Double-Premium Beds',amount:this.state.double_bed_premium_amount})} sx={{height:100,backgroundColor:'#fff',display:'flex',justifyContent:'center',borderRadius:1, '&:hover': {
    backgroundColor: '#5cd1d5',
    
  },}}>
  <Box sx={{display:'flex',alignItems:'center',}}>
  <Box sx={{mr:3}}>
  <img
  alt='logo' src={dd} style={{height:45,width:'100%',mr:4}}
  />
  </Box>
  <Box sx={{ml:3,display:'flex',flexDirection:'column'}}> 
  <Typography  sx={{fontSize:13,fontWeight:'450'}}>Double-Premium Beds</Typography>
  <Typography  sx={{fontSize:23,fontWeight:'600'}}>₹ {this.state.double_bed_premium_amount}</Typography>
  </Box>

  
  </Box>
  </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={5}>
  <Box onClick={()=>this.setState({bed:'Double Bed',amount:this.state.double_bed_amount})}  sx={{height:100,backgroundColor:'#fff',display:'flex',justifyContent:'center',borderRadius:1, '&:hover': {
    backgroundColor: '#5cd1d5',
    
  },}}>
  <Box sx={{display:'flex',alignItems:'center',}}>
  <Box sx={{mr:3}}>
  <img
  alt='logo' src={dd} style={{height:45,width:'100%',mr:4}}
  />
  </Box>
  <Box sx={{ml:3,display:'flex',flexDirection:'column'}}> 
  <Typography  sx={{fontSize:13,fontWeight:'450'}}>Double Beds</Typography>
  <Typography  sx={{fontSize:23,fontWeight:'600'}}>₹ {this.state.double_bed_amount}</Typography>
  </Box>

  
  </Box>
  </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={5}>
  <Box onClick={()=>this.setState({bed:'Single Bed',amount:this.state.single_bed_amount})} sx={{height:100,backgroundColor:'#fff',display:'flex',justifyContent:'center',borderRadius:1, '&:hover': {
    backgroundColor: '#5cd1d5',
    
  },}}>
  <Box sx={{display:'flex',alignItems:'center',}}>
  <Box sx={{mr:3}}>
  <img
  alt='logo' src={single} style={{height:45,width:'100%',mr:4}}
  />
  </Box>
  <Box sx={{ml:3,display:'flex',flexDirection:'column'}}> 
  <Typography  sx={{fontSize:13,fontWeight:'450'}}>Single Bed</Typography>
  <Typography  sx={{fontSize:23,fontWeight:'700'}}>₹ {this.state.single_bed_amount}</Typography>
  </Box>
  </Box>
  </Box>
  </Grid>
</Grid>
 </Box>
</AccordionDetails>
</Accordion>
</Box>

<Box sx={{mb:0.7}}>
<Accordion expanded={this.state.expanded === 'panel2'} onChange={()=>{this.setState({expanded:'panel2'})}}>
<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:this.state.expanded==='panel2'?"#f1f4f6":"white"}}>
  <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>Covid Policy</Typography>
</AccordionSummary>
<AccordionDetails sx={{backgroundColor:'#f1f4f6'}}>
<Box sx={{minHeight:200,backgroundColor:'#fff'}}>
<br/>
 <Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Like a standard residential accommodation, we remain operational during covid and continue to provide services. If we need to stop our services due to any government orders, we will pause your contract for that duration and re-start it when we can restart services.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>If there is a government lockdown and the university officially notifies you of online classes and you decide to move out, you pay the complete amount for the ongoing month. The fees for the subsequent months will be discounted with operational facilities such as food.No discounts if you continue to stay at your-space facilities.</Typography>
</Box>
</Box>


 
 </Box>
</AccordionDetails>
</Accordion>
</Box>

<Box sx={{mb:0.7}}>
<Accordion expanded={this.state.expanded === 'panel3'} onChange={()=>{this.setState({expanded:'panel3'})}}>
<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:this.state.expanded==='panel3'?"#f1f4f6":"white"}}>
  <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>
Payment & Booking Policy</Typography>
</AccordionSummary>
<AccordionDetails sx={{backgroundColor:'#f1f4f6'}}>
 <Box sx={{minHeight:200,backgroundColor:'#fff'}}>
<br/>
 <Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Prior to check-in, we require the resident to pay the first month’s fee and their entire security deposit. We only charge 1-2 months’ of security deposit however, this is subjective to property. Please contact our onsite team via Whatsapp or call us at +91-78748748585 for more information.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>At certain locations, where the payment for 3, 6 or 12 months needs to be made in advance we require that entire advance to be paid before check-in. A delay in payment beyond the 10th of each month will lead to INR 100 per day as a penalty that will be added to the following month’s fee.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>As part of the promotional offer, you can start your reservation process by paying only INR.1000 and complete your booking upon a total payment of INR. 10000, non-refundable. Our team will be in touch to assist you throughout.</Typography>
</Box>
</Box>


 
 
 
 </Box>
</AccordionDetails>
</Accordion>
</Box>

<Box sx={{mb:0.7}}>
<Accordion expanded={this.state.expanded === 'panel4'} onChange={()=>{this.setState({expanded:'panel4'})}}>
<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:this.state.expanded==='panel4'?"#f1f4f6":"white"}}>
  <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>Cancelling Policy</Typography>
</AccordionSummary>
<AccordionDetails sx={{backgroundColor:'#f1f4f6'}}>
<Box sx={{minHeight:200,backgroundColor:'#fff'}}>
<br/>
 <Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Our standard legally binding agreement is for 12 months, treated as the lock-in period once the tenant books the property.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>If the tenant chooses to vacate the property prior to the end of the lock-in period, the fee for the entire lock-in period is still due and the security deposit will not be refunded.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>If the tenant books but does not check in to the property/cancels the booking within 24hrs of the check-in date then 25% of the entire amount will be deducted and the rest will be refunded.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>If the cancellation is made after 24 hours of the check-in date then the entire amount paid prior to check-in will stand forfeited.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:1}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'black',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:11,sm:11,md:13,lg:13}}}>At the end of the term, the tenant must give 2 months’ notice in writing to vacate the property. Failing this, the contract is assumed to be renewed for another 12 months, which will be treated as lock-in.</Typography>
</Box>
</Box>


 
 
 
 </Box>
</AccordionDetails>
</Accordion>
</Box>

       </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Box>
        <br/>
        <Card sx={{minHeight:400,backgroundColor:'#fff'}}>
        <br/>
        <br/>
        <Typography variant='h1' sx={{fontSize:19,fontWeight:'700',ml:3}}>Summary</Typography>
        
<Box sx={{backgroundColor:'#5cd1d5',m:2,height:100,borderRadius:1,display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',alignItems:'center',}}>
  <Box sx={{mr:3}}>
  <img
  alt='logo' src={single} style={{height:45,width:'100%',mr:4}}
  />
  </Box>
  <Box sx={{ml:3,display:'flex',flexDirection:'column'}}> 
  <Typography  sx={{fontSize:13,fontWeight:'450'}}>{this.state.bed}</Typography>
  <Typography  sx={{fontSize:23,fontWeight:'600',ml:10}}>₹ {this.state.amount}</Typography>
  </Box>
  </Box>
</Box>

<Box sx={{display:'flex',justifyContent:"space-between",ml:3,mr:3}}>
<Typography sx={{fontSize:16,fontWeight:'450'}}>Room Price /1 Month</Typography>
<Typography  sx={{fontSize:16,fontWeight:'600',ml:10}}>₹ {this.state.amount}</Typography>
</Box>

<Box sx={{display:'flex',justifyContent:"space-between",ml:3,mr:3,mt:1}}>
<Typography sx={{fontSize:16,fontWeight:'450'}}>GST</Typography>
<Typography  sx={{fontSize:16,fontWeight:'600',ml:10}}>₹ 2990</Typography>
</Box>

<Box sx={{display:'flex',justifyContent:"space-between",ml:3,mr:3,mt:1}}>
<Typography sx={{fontSize:19,fontWeight:'750',fontFamily:'serif'}}>Booking amount</Typography>
<Typography  sx={{fontSize:18,fontWeight:'600',ml:10}}>₹ 1000</Typography>
</Box>
<br/>




<Box sx={{minHeight:10,backgroundColor:'#fefefe'}}>
<Box sx={{display:'flex',justifyContent:"space-between",ml:3,mr:3,mt:1}}>
<Typography sx={{fontSize:19,fontWeight:'750',fontFamily:'serif'}}>Payable Now</Typography>
<Typography  sx={{fontSize:21,fontWeight:'700',ml:10}}>₹ 1000</Typography>
</Box>
</Box>


<Box onClick={this.cheakout} sx={{backgroundColor:'#c7167a',height:50,display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{fontSize:21,fontWeight:'550',color:'white'}}>Continue Booking</Typography>
</Box>
</Box>



        
        </Card>
        </Box>
      </Grid>
    </Grid>

      












      </Box>
      </Container>
      </Box>
      </Box>
      </div>
    )
  }
}


export function Formc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Form navigate={navigate}></Form>)
}


export default Form