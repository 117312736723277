import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import firebase from  "../firebase"
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment'
import { Typography,Box, Container,Button ,TextField} from '@mui/material';







class Enquiry extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
           bookingdata :[],
           search:"",
        }
        this.handleChange = this.handleChange.bind(this)
      }
  
      handleChange=(e)=>{
  this.setState({[e.target.name]:e.target.value})
  }
  

    componentDidMount(){
        var rb = firebase.database().ref().child("Enquiry");
        let cont = [];
        rb.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
             
              var childData = childSnapshot.val();
              
              cont.push(childData);
      
            this.setState({bookingdata:cont})
            console.log(cont)
           
            });
          });
    
    
    }
    


    render() {
        return (
            <div>
                <Container maxWidth='xl'>
                <Box>

<Box sx={{display:'flex',flexDirection:'row'}}>

<Box >
<TextField id="outlined-basic" sx={{mt:-4}} onChange={this.handleChange} size='small' label="search name" variant="outlined" name='search' />
</Box>



</Box>


                
<br/>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
        
            <TableCell sx={{fontWeight:600}} align="center">Date</TableCell>
            <TableCell sx={{fontWeight:600}} align='center'>phone</TableCell>
            <TableCell sx={{fontWeight:600}} align='center'>Button</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          { this.state.bookingdata.map((row) => (
            <TableRow
              key='w4'
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="center">{row.mobilenumber}</TableCell>
              <TableCell align="center"><Button onClick={()=>firebase.database().ref('Enquiry').child(row.key).remove()} sx={{textTransform:'none'}}  size='small' variant="contained">Delete</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


                </Box>
                </Container>
            </div>
        );
    }
}

export default Enquiry;



const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];