import Carousel from 'react-bootstrap/Carousel';
import logo from "../src/logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import one from "../src/img/one.webp"
import two from "../src/img/two.webp"
function Mobilehorrizontal() {
  return (
    <Carousel>
      <Carousel.Item>
      <img
      className="d-block w-100"
      src={one}
      alt="Second slide"
      style={{height:400,objectFit:'contain'}}
    />
       
      </Carousel.Item>
      <Carousel.Item>
      <img
      className="d-block w-100"
      src={two}
      alt="Second slide"
      style={{height:400,objectFit:'contain'}}
    />

        
      </Carousel.Item>
      <Carousel.Item>
      <img
      className="d-block w-100"
      src={one}
      alt="Second slide"
      style={{height:400,objectFit:'contain'}}
    />

      </Carousel.Item>
    </Carousel>
  );
}

export default Mobilehorrizontal;








