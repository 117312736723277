import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { Paper,Box,Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14}}>
    FAQs
    </Typography>,
  ];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgb(224,245,254)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faqs() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
<Box sx={{backgroundColor:'#f1f4f6'}}>
<Box sx={{mt:6,height:80,paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
<br/>
<Container maxWidth='xl'>
<Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
</Container>


</Box>
</Box>

    <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
    <Container maxWidth='xl'>
    <Box sx={{backgroundColor:"#00337b",minHeight:110,}}>
    <Box>
    <br/>
    <Typography align='left' sx={{color:"white",fontSize:25,marginLeft:5,fontFamily:'sans-serif'}}>Frequently Asked Questions
    </Typography>
    
    </Box>
    </Box>



    <br/>
    <Paper elevation={1} variant="outlined" square >
<Box>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
          <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>1. How can I book a room for myself?</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor:'white'}}>
          <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#1c446a',fontSize:{xs:14,sm:14,md:16}}}>
           Fill this form with your details and we will get back to you.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
        <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>2. Who will keep my room and the common areas clean?</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{backgroundColor:'white'}}>
        <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>
        You can leave that to us. We have a dedicated housekeeping and maintenance staff in all of our hostels who ensure a spick and span space at all times. We ensure there is daily housekeeping so that you don’t worry about hygiene.
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
      <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>3. Can I cook my own meals at Dhiyaar Living hostel?</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{backgroundColor:'white'}}>
      <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>
      Unfortunately, in most of our hostels, you cannot cook your own meals. We do provide an induction plate for basic cooking so that you can cook your favourite Maggi and chai to get the hostel feels! Our food is rated India’s best hostel food, so it isn’t often that you would need to cook anyway ? We have also started a special a la carte menu where you can choose from a range of specials on our app and get those added to your meal the next day!
      </Typography>
    </AccordionDetails>
  </Accordion>  

  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
    <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>3. Can I cook my own meals at Dhiyaar Living hostel?</Typography>
  </AccordionSummary>
  <AccordionDetails sx={{backgroundColor:'white'}}>
    <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>
    Unfortunately, in most of our hostels, you cannot cook your own meals. We do provide an induction plate for basic cooking so that you can cook your favourite Maggi and chai to get the hostel feels! Our food is rated India’s best hostel food, so it isn’t often that you would need to cook anyway ? We have also started a special a la carte menu where you can choose from a range of specials on our app and get those added to your meal the next day!
    </Typography>
  </AccordionDetails>
</Accordion>  


<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
      <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>3. Can I cook my own meals at Dhiyaar Living hostel?</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{backgroundColor:'white'}}>
      <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>
      Unfortunately, in most of our hostels, you cannot cook your own meals. We do provide an induction plate for basic cooking so that you can cook your favourite Maggi and chai to get the hostel feels! Our food is rated India’s best hostel food, so it isn’t often that you would need to cook anyway ? We have also started a special a la carte menu where you can choose from a range of specials on our app and get those added to your meal the next day!
      </Typography>
    </AccordionDetails>
  </Accordion>  


  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
    <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>3. Can I cook my own meals at Dhiyaar Living hostel?</Typography>
  </AccordionSummary>
  <AccordionDetails sx={{backgroundColor:'white'}}>
    <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>
    Unfortunately, in most of our hostels, you cannot cook your own meals. We do provide an induction plate for basic cooking so that you can cook your favourite Maggi and chai to get the hostel feels! Our food is rated India’s best hostel food, so it isn’t often that you would need to cook anyway ? We have also started a special a la carte menu where you can choose from a range of specials on our app and get those added to your meal the next day!
    </Typography>
  </AccordionDetails>
</Accordion>  


<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{backgroundColor:'white'}}>
      <Typography sx={{fontWeight:600, fontFamily:'serif',fontSize:{xs:15,sm:15,md:17},color:'#c7167a'}}>3. Can I cook my own meals at Dhiyaar Living hostel?</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{backgroundColor:'white'}}>
      <Typography sx={{fontWeight:500, fontFamily:'serif',color:'#00337b',fontSize:{xs:14,sm:14,md:16}}}>
      Unfortunately, in most of our hostels, you cannot cook your own meals. We do provide an induction plate for basic cooking so that you can cook your favourite Maggi and chai to get the hostel feels! Our food is rated India’s best hostel food, so it isn’t often that you would need to cook anyway ? We have also started a special a la carte menu where you can choose from a range of specials on our app and get those added to your meal the next day!
      </Typography>
    </AccordionDetails>
  </Accordion>  









  
      </Box>
      </Paper>
      <br/>
      </Container>
      
      
      </Box>
    </div>
  );
}






