import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import sv from "../src/sv.png"
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import Booking from '../src/Pages/Booking';
import Enquiry from '../src/Pages/Enquiry';
import Career from '../src/Pages/Career';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import Trash from '../src/Pages/Trash';
import Add from '../src/Pages/Add';
const drawerWidth = 240;

function Mainadmin(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [open,setOpnen] = React.useState("1")

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
     
<Box>
<img src={sv} style={{height:56,width:'100%',objectFit:'cover',m:1}}/>

</Box>

      <Divider />
      <br/>
      <List>
        {[{"name":"Booking","icon":<BookOnlineIcon/>,"value":'1'},{"name":"Enquiry","icon":<PersonSearchIcon/>,"value":'2'},{"name":"Career","icon":<WorkOutlineIcon/>,"value":'3'},{"name":"Trash","icon":<AutoDeleteIcon/>,"value":'4'},{"name":"Add","icon":<InboxIcon/>,"value":'6'} ].map((text, index) => (
        
         <ListItem key={text} disablePadding >
            <ListItemButton onClick={()=>setOpnen(text.value)}>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <Typography sx={{fontWeight:400}}>{text.name}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
            backgroundColor:'#010229',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
           Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >

        <Toolbar />
<Toolbar/>





        <Box>


          {
            open=="1"?<Booking/>:null
          }

          {
            open=="2"?<Enquiry/>:null
          }

          {
            open=="3"?<Career/>:null
          }

         {
            open=="4"?<Trash/>:null
          }
          {
            open=="5"?<Add/>:null
          }

          {
            open=="6"?<Add/>:null
          }






        </Box>






      </Box>
    </Box>
  );
}

Mainadmin.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Mainadmin;