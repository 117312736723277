import React, { Component } from 'react'
import { Container,Typography,Card,Grid,Divider,TextField,Button,Box,Link} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import snp from "../src/Imgforp/snp.webp"
import AdjustIcon from '@mui/icons-material/Adjust';
import map2 from "../src/Imgforp/ggg.png"

const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
    Home
  </Link>,
 
  <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
  Investor
  </Typography>,
];

export class Investor extends Component {
  render() {
    return (
      <div>
    <Box sx={{minHeight:200,backgroundColor:'#e1e9e9'}}>
    <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <br/>
      <br/>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
      {breadcrumbs}
    </Breadcrumbs>
      <br/>
      <Box sx={{ml:{xs:'1%',sm:'1%',md:'10%'},mr:{xs:'1%',sm:'1%',md:'10%',}}}>
      <Typography align='center' sx={{fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:14,sm:14,md:18,lg:18}}}>
      We at your space  aim to offer holistic living spaces that deliver the utmost comfort, convenience and a nurturing student environment.With accredited investors as one of our strongest pillars, we aim to provide students with not just an accommodation but a lifestyle that is laced with a homely feel, through and through.Our brand ethos is shared by the esteemed panel of investors we have on board. With a unified vision, we aim to constantly enhance our service and drive business growth.
      </Typography>
      </Box>
      </Container>
    </Box>
    <br/>
    <br/>
    </Box>



    <Box sx={{minHeight:200,backgroundColor:'#e1e9e9'}}>
    <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},width:'100%'}}>
      <Container maxWidth='xl'>
    <Card sx={{backgroundImage: `url(${map2})`,minHeight:100,backgroundColor:'#022246 ',opacity:0.9}}>
    <Box >
    <Grid container spacing={3}>
    <Grid item xs={12} sm={12} md={3}>
    <Box sx={{backgroundColor:'#ed73a3',height:300,width:{xs:'100%',sm:'100%',md:'100%'}}}>
   <img
   className='d-block w-100'
   src={snp}
   alt=''
   style={{objectFit:'contain',height:300}}
   />
  </Box>
    
    </Grid>
    <Grid item xs={12} sm={12} md={9}>
    <Box sx={{minHight:100}}>
    <br/>
    
   <Typography sx={{ml:{xs:1,sm:1,md:4},mb:1,fontWeight:'735',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:20,sm:20,md:30,lg:30}}}>Mr. K.R. Kamath</Typography>
   <Typography sx={{ml:{xs:1,sm:1,md:4},mb:2,fontWeight:'735',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:15,sm:15,md:25,lg:25}}}>ceo</Typography>

    <Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>




<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>
    </Box>
    </Grid>
  </Grid>
    </Box>
    </Card>
    </Container>
    </Box>
    </Box>
    



    <Box sx={{minHeight:200,backgroundColor:'#e1e9e9',mt:2}}>
    <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},width:'100%'}}>
      <Container maxWidth='xl'>
    <Card sx={{backgroundImage: `url(${map2})`,minHeight:100,backgroundColor:'#022246 ',opacity:0.9}}>
    <Box >
    <Grid container spacing={3}>
    <Grid item xs={12} sm={12} md={3}>
    <Box sx={{backgroundColor:'#ed73a3',height:300,width:{xs:'100%',sm:'100%',md:'100%'}}}>
   <img
   className='d-block w-100'
   src={snp}
   alt=''
   style={{objectFit:'contain',height:300}}
   />
  </Box>
    
    </Grid>
    <Grid item xs={12} sm={12} md={9}>
    <Box sx={{minHight:100}}>
    <br/>
    
   <Typography sx={{ml:{xs:1,sm:1,md:4},mb:1,fontWeight:'735',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:20,sm:20,md:30,lg:30}}}>Mr. K.R. Kamath</Typography>
   <Typography sx={{ml:{xs:1,sm:1,md:4},mb:2,fontWeight:'735',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:15,sm:15,md:25,lg:25}}}>ceo</Typography>

    <Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>
    </Box>
    </Grid>
  </Grid>
    </Box>
    </Card>
    </Container>
    </Box>
    </Box>
       
      

    <Box sx={{minHeight:200,backgroundColor:'#e1e9e9',mt:2}}>
    <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},width:'100%'}}>
      <Container maxWidth='xl'>
    <Card sx={{backgroundImage: `url(${map2})`,minHeight:100,backgroundColor:'#022246 ',opacity:0.9}}>
    <Box >
    <Grid container spacing={3}>
    <Grid item xs={12} sm={12} md={3}>
    <Box sx={{backgroundColor:'#ed73a3',height:300,width:{xs:'100%',sm:'100%',md:'100%'}}}>
   <img
   className='d-block w-100'
   src={snp}
   alt=''
   style={{objectFit:'contain',height:300}}
   />
  </Box>
    
    </Grid>
    <Grid item xs={12} sm={12} md={9}>
    <Box sx={{minHight:100}}>
    <br/>
    
   <Typography sx={{ml:{xs:1,sm:1,md:4},mb:1,fontWeight:'735',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:20,sm:20,md:30,lg:30}}}>Mr. K.R. Kamath</Typography>
   <Typography sx={{ml:{xs:1,sm:1,md:4},mb:2,fontWeight:'735',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:15,sm:15,md:25,lg:25}}}>ceo</Typography>

    <Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',ml:{xs:1,sm:1,md:4}}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>38 years of experience in 4 public sector banks, two as Chairman & MD.</Typography>
</Box>
</Box>
    </Box>
    </Grid>
  </Grid>
    </Box>
    </Card>
    </Container>
    </Box>
    </Box>
    









      
      
      </div>
    )
  }
}

export default Investor